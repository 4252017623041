import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialog } from "@angular/material/dialog";
import { practitioner } from '../model/Practitioner';
import { WebApiService } from '../services/web-api.service';
import { app_type_for_clinic } from '../model/Appointment_type_clinic'
import { Clinic } from '../model/Clinic';

@Component({
  selector: 'app-add-appointment-type',
  templateUrl: './add-appointment-type.component.html',
  styleUrls: ['./add-appointment-type.component.scss']
})
export class AddAppointmentTypeComponent implements OnInit {
  errorMessage: string = "";
  appType: app_type_for_clinic = new app_type_for_clinic();
  isEditing: boolean = false;
  idClinic: number = Number(sessionStorage.getItem('idClinic'));
  clinic: Clinic;
  isError: boolean = false;
  constructor(private webApiService: WebApiService, public matDialog:MatDialog, public dialogRef: MatDialogRef<AddAppointmentTypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    {
      dialogRef.disableClose = true;
      if(data.appType != null)
      {
        this.isEditing = true;
        this.appType = {...data.appType}
      }
    }

  async ngOnInit()
  {
    await this.LoadClinic();
  }

  async InsertAppType()
  {
    this.isError = false;
    if(this.ValidateInput())
    {
      this.appType.idClinic = Number(sessionStorage.getItem('idClinic'));
      //this.appType.color = this.clinic.color.btnAppType;
      let ret;
      if(!this.isEditing)
      {
         ret = await this.webApiService.InsertAppointmentType(this.appType);
      }
      else
      {
        ret = await this.webApiService.UpdateAppointmentType(this.appType);
      }
      //vidjeti zasto je ret null i kad ubaci uspjesno
      this.CloseModal(true);
    }
  }

  async LoadClinic()
  {
    this.clinic = await this.webApiService.GetClinicById();
    if(!this.isEditing)
    {
      this.appType.color = this.clinic.color.btnAppType;
    }
  }

  CloseModal(flag: boolean)
  {
    this.dialogRef.close(flag);
  }

  ValidateInput()
  {
    if(this.appType.descriptionDe == "" || this.appType.descriptionEn == "")
    {
      this.errorMessage = "Enter all fields with *";
      this.isError = true;
      return false;
    }
    return true;
  }



}
