<!--<ng-container>
    <button (click)="Confirm(false)"  mat-icon-button class="close-button2" [mat-dialog-close]="true">
        <mat-icon class="close-icon" color="warn">close</mat-icon>
      </button>
    <div mat-dialog-content>
        <div style="text-align: center;margin-left: 15px;margin-top: 10px;">
        <p mat-dialog-title>Delete confirmation</p>
        </div>
        <p style="margin-top: 20px;" class="mystyle_font text-center">{{message}}</p>
    </div>
    <div style="display: flex;" mat-dialog-actions>
        <button class="btn confirm-button" style="width: 50%;margin-right: 10px;" mat-button (click)="Confirm(true)">Yes</button>
        <button class="btn decline-button" style="width: 50%;margin-left: 10px;" mat-button (click)="Confirm(false)">No</button>
    </div>
</ng-container>-->

<div class="body">
    <div class="title">
        Bestätigung löschen
    </div>
    <div class="question">
        {{message}}
    </div>
    <div class="options">
        <button class="btn_edit"  mat-button (click)="Confirm(true)">Ja</button>
        <button class="btn_delete"  mat-button (click)="Confirm(false)">Nein</button>
    </div>
</div>
