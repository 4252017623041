import { practitioner } from './Practitioner';
import { patient } from './Patient';
import { app_type_for_practitioner } from './Appointment_type_practitioner'
export class Appointment 
{
    constructor()
    {
        this.isAppointmentSelected = false
    }
    public idAppointment: number;
    public idClinic: number;
    public idPatient: number;
    public idPractitioner: number;
    public idAppType: number;
    public date: Date;
    public timeFrom: string;
    public timeTo: string;
    public idAppointmentStatus: number;
    public idAppointmentEDP: number
    public createdOnline: boolean;
    public patient: patient;
    public practitioner: practitioner
    public appointmentType: app_type_for_practitioner;
    //nova polja
    public timeOfBooking: Date;
    public dateOfConfirmation: Date;
    public message: string;
    public cancellingDate: Date;
    public cancelingReason: string;

    //propertiji fullcalendara koji moraju postojati
    public title:string;
    public start:Date;
    public end:Date;
    public color:string;
    public resourceId:string;
    public description: string;
    public appTypeColor: string;
    public appTypeName: string;
    public remarks: string;

    public isAppointmentSelected: boolean = false;


}