<div class="content">
    <div class="header">
        Arbeitszeit hinzufügen
    </div>
    <div class="form">
        <div class="time-pick">
            <div style="display: flex;">
                <div class="from-to">Aus</div>
                <input [(ngModel)] = "selectedTimeFrom" [format]="24" [ngxTimepicker]="picker1" class="input_field time-input">
                <ngx-material-timepicker-toggle class="time-input-button"  [for]="picker1"></ngx-material-timepicker-toggle>
                <ngx-material-timepicker (timeSet) = "TimeFromSelected($event)" [minutesGap]="15" #picker1></ngx-material-timepicker>
            </div>

            <div style="display: flex;">
                <div class="from-to">Zu</div>
                <input [(ngModel)] = "selectedTimeTo" [format]="24" [ngxTimepicker]="picker2" class="input_field time-input">
                <ngx-material-timepicker-toggle class="time-input-button"  [for]="picker2"></ngx-material-timepicker-toggle>
                <ngx-material-timepicker [minutesGap]="15" #picker2></ngx-material-timepicker>
            </div>
        </div>
    </div>
    <div class="close_modal">
        <button (click) = "CloseModal()"  class="btn_close">
            Abbruch
        </button>
        <button (click) = "AddNewTermin()"  class="btn_confirm">
            Hinzufügen
        </button>
    </div>
</div>

