export class app_type_for_clinic
{
    public id: number; 
    public idClinic: number; 
    public descriptionDe: string = "";
    public descriptionEn: string = ""; 
    public descriptionIt: string = "";  
    public delay: number = 0; 
    public color: string; 
    public duration: number = 0;
}