import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialog } from "@angular/material/dialog";
@Component({
  selector: 'app-question-modal',
  templateUrl: './question-modal.component.html',
  styleUrls: ['./question-modal.component.scss']
})
export class QuestionModalComponent implements OnInit {
  message: string = "";
  constructor(public matDialog:MatDialog, public dialogRef: MatDialogRef<QuestionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any)  
    {
      dialogRef.disableClose = true;
      this.message = data.question;
    }

  ngOnInit(): void {
  }

  Confirm(flag: boolean)
  {
    this.dialogRef.close(flag);
  }
}
