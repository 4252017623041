<div *ngIf = "showAll" class="header">
	ALLE ONLINE-TERMINE
</div>
<div *ngIf = "onlyNew" class="header">
	NEUE TERMINE
</div>
<div *ngIf = "onlyManual" class="header">
	MANUELLE TERMINE
</div>
<button *ngIf = "onlyManual" (click) = "AddNewManualAppointment()" class="add_button btn_confirm">Neuen Termin hinzufügen</button>
<div class="container">
	<div class="appointments-actions">
		<div>
			<button *ngIf="showAll" (click) = "ToggleDiv()" style="width: auto !important; margin-bottom: 10px;" class="btn_confirm">
				<i style ="font-size: 16px;" class='bx bx-filter'></i>
				Filter
			</button>
			<div *ngIf="showAll" class="filter-div" [ngClass]="{'filter-div-hidden': isHidden}"> 
				<div class="tabs">
					<button class="tab" [class.active]="activeTab === 1" (click)="activeTab = 1; SwitchPeriod(1)">Zeitraum</button>
					<button class="tab" [class.active]="activeTab === 2" (click)="activeTab = 2; SwitchPeriod(2)">Monatszeitraum</button>
				</div>
				<div style="display: block; margin-top: 5px;">
					<div style="display: flex;width: 150px; margin-right: 5px;" *ngIf = "activeTab === 1">
						<div style="line-height: 35px;  margin-right: 5px;">From: </div>
						<app-date-picker style="margin-right: 5px;" [pickerWidth] = "'141px'"  (dateChange)="onSelectedDateFromChanged($event)" [selectedDate]="selectedDateFrom"></app-date-picker>
						<div style="line-height: 35px;  margin-right: 5px;">To: </div>
						<app-date-picker [pickerWidth] = "'141px'" (dateChange)="onSelectedDateToChanged($event)" [selectedDate]="selectedDateTo"></app-date-picker>
					</div>
					<div style="display: flex" *ngIf = "activeTab === 2">
						<div style="line-height: 35px;  margin-right: 5px;">Year: </div>
						<select (input)="YearChanged($event.target.value)" style="text-align: start; margin-right: 5px;" [(ngModel)]="selectedYear" class="select_control input_fields">
							<option [ngValue] = "year" *ngFor="let year of years">{{year}}</option>
						</select>
						<div style="line-height: 35px;  margin-right: 5px;">Month: </div>
						<select (input)="MonthChanged($event.target.value)" style="text-align: start;" [(ngModel)]="selectedMonth" class="select_control input_fields">
							<option [ngValue] = "month" *ngFor="let month of months">{{month}}</option>
						</select>
					</div>
				</div>

				<div style="display: flex; margin-top: 5px;">
					<div style="line-height: 35px; width: 120px;">Behandler: </div>
					<select style="text-align: start;" (input)="FilterPractitionerChanged($event.target.value)" [(ngModel)]="selectedPractId" class="select_control input_fields">
						<option [ngValue] = "-1">Alle</option>
						<option [ngValue] = "pract.idPractitioner" *ngFor="let pract of practitioners">{{pract.name}}</option>
					</select>
				</div>

				<div style="display: flex; margin-top: 5px;">
					<div style="line-height: 35px; width: 120px;">Status: </div>
					<select style="text-align: start;" [disabled]="onlyNew || onlyManual" (input)="FilterStatusChanged($event.target.value)"  [(ngModel)]="selectedStatusId" class="select_control input_fields">
						<option [ngValue] = "4">Alle</option>
						<option [ngValue] = "status.idStatus" *ngFor="let status of Statuses">{{status.statusNameEn}}</option>
					</select>
				</div>
			</div>

			<div *ngIf="onlyNew" style="display: flex; margin-top: 5px;">
				<div style="line-height: 35px; width: 120px;">Behandler: </div>
				<select style="text-align: start;" (input)="FilterPractitionerChanged($event.target.value)" [(ngModel)]="selectedPractId" class="select_control input_fields">
					<option [ngValue] = "-1">Alle</option>
					<option [ngValue] = "pract.idPractitioner" *ngFor="let pract of practitioners">{{pract.name}}</option>
				</select>
			</div>

		</div>
		<!--<div *ngIf = "!onlyNew" class="date_range">
			{{monthStr}}
		</div>-->
		
		<!--<div *ngIf = "!onlyNew" class="navigation-block">
			<div (click) = "GoMonthBackward()" class="arrow">
				<i style="line-height: 35px;" class='bx bx-chevron-left arrow-icon'></i>
			</div>
			<button (click)="GoToToday()" class="btn_today">Aktueller Monat</button>
			<div (click) = "GoMonthForward()" class="arrow">
				<i style="line-height: 35px;" class='bx bx-chevron-right arrow-icon'></i>
			</div>
		</div>-->		
	</div>
	<table>
		<thead>
		  <tr>
			<th>Buchung</th>
			<th>Vollständiger Name</th>
			<th>Datum</th>
			<th>Von</th>
			<th>Bis</th>
			<th>Terminart</th>
			<th>Nachricht</th>
			<th>Behandler</th>
			<th>Bestätigung</th>
			<th>Stornierung</th>
			<th>Bemerkungen</th>
			<th>Status</th>
			<th *ngIf = "showAll || onlyManual" style="width: 30px;"></th>
		  </tr>
		</thead>
		<tbody>
			<!--<tr *ngIf = "showAll">
				<td></td>
				<td></td>
				<td lowPad data-column="Search by name">
					<input *ngIf = "showAll" (input)="FilterPatientNameChanged($event.target.value)" placeholder="Patienten suchen..." class="input_fields" type="text"/>
				</td>
				<td lowPad data-column="Search by date">
					<app-date-picker [pickerWidth] = "'150px'"  *ngIf = "showAll" (dateChange)="onSelectedDateChanged($event)" [selectedDate]="selectedDate"></app-date-picker>
				</td>
				<td></td>
				<td></td>
				<td></td>
				<td></td>
				<td lowPad data-column="Search by pract">
					<input *ngIf = "showAll" (input)="FilterPractNameChanged($event.target.value)" placeholder="Search practitioner..." class="input_fields" type="text"/>
				</td>
				<td></td>
				<td></td>
				<td></td>
				<td data-column="Search by status" lowPad>
					<select [disabled]="onlyNew || onlyManual" (input)="FilterStatusChanged($event.target.value)"  [(ngModel)]="selectedStatusId" class="select_control input_fields">
						<option [ngValue] = "3">All</option>
						<option [ngValue] = "status.idStatus" *ngFor="let status of Statuses">{{status.statusNameEn}}</option>
					</select>
				</td>
			</tr>-->
			<tr *ngFor="let app of FilteredAppointments">
				<td data-column="Time of booking">{{app?.timeOfBooking | dateFormat}}</td>
				<td data-column="Name">{{app.patient?.firstName}} {{app.patient?.lastName}}</td>
				<td data-column="Date">{{app.date | dateFormat}}</td>
				<td data-column="From">{{app.timeFrom | dateToTime}}</td>
				<td data-column="To">{{app.timeTo | dateToTime}}</td>
				<td data-column="Appointment type">{{app.appointmentType?.appTypeClinic.descriptionDe}}</td>
				<td data-column="Message">{{app?.message}}</td>
				<td data-column="Practitioner">{{app.practitioner.name}}</td>
				<td data-column="Confirmation date">{{app?.dateOfConfirmation | dateFormat}}</td>
				<td data-column="Canceling date">{{app?.cancellingDate | dateFormat}}</td>
				<td title ="{{app?.remarks}}" data-column="Remarks">
					<div class="hide-text">
						{{app?.remarks}}
					</div>
				</td>
				<td data-column="Status">{{app.statusStr}}</td>
				<td *ngIf = "showAll || onlyManual" data-column="Delete">
					<div style="display: flex;">
						<div title="Confirm" *ngIf = "onlyNew && !clinic.clientHasEDP" (click) = "ConfirmSelectedPendingAppointment(app)" class="table-button"  style="background:#5cb85c;" >
							<i style="font-size: 20px;" class='bx bx-check'></i>
						</div>
						<div title="Reject" *ngIf = "onlyNew && !clinic.clientHasEDP" (click) = "RejectSelectedPendingAppointment(app)"  class="table-button" style="background: rgba(169, 114, 33, 0.86);" >
							<i style="font-size: 20px;" class='bx bxs-edit'></i>
						</div>
						<div title="Edit" *ngIf = "onlyManual" (click) = "EditSelectedManualAppointment(app)"  class="table-button" style="background: #4869b1;" >
							<i style="font-size: 20px;" class='bx bxs-edit'></i>
						</div>			
						<div title="Delete" *ngIf = "showAll || onlyManual" (click) = "DeleteSelectedAppointment(app)"  class="table-button" style="background: #d9534f;" >
							<i style="font-size: 20px;" class='bx bxs-trash-alt'></i>
						</div>
					</div>
				</td>
			</tr>
		</tbody>
	  </table>
	<div *ngIf = "FilteredAppointments.length < 1" class="no_appointments_note">Keine Termine.</div>
</div>
