<div class="header">
    FEIERTAGE
</div>
<div class="outter-container">
    <div style="display: flex;">
        <div style="line-height: 35px;  margin-right: 5px;">Kanton: </div>
        <select style="text-align: start;" [(ngModel)]="selectedCanton" class="select_control input_fields">
            <option [ngValue] = "canton" *ngFor="let canton of cantons">{{canton.name}}</option>
        </select>
    </div>
    <button (click) = "ChangeCanton()" style="width: auto !important; margin-bottom: 10px; float: right; margin-top: 10px; margin-bottom: 20px;" class="btn_confirm">
        <i style ="font-size: 16px;" class='bx bx-save'></i>
        Kanton wechseln
    </button>
    <div *ngFor="let holiday of clinicHolidays" style="width: fit-content; border-radius: 5px;border: 2px solid lightgray; display: flex; flex-direction: row; padding: 15px; margin-bottom: 10px; background: white; box-shadow: 2px 4px 8px rgb(228, 227, 227);">

        <div style="margin-right: 20px; display: flex; align-items: center; border-right: 2px solid lightgray; padding-right: 10px; font-weight: 550; width: 200px;">{{holiday.name}}</div>
        
        <div style="display: flex; align-items: center; border-right: 2px solid lightgray; padding-right: 10px;">
            Wird gefeiert?
            <input (change) = "CheckboxChanged($event, holiday)" [disabled] = "holiday.isMandatory" style="width:20px;height:20px; margin-left: 10px;" type="checkbox" [(ngModel)] = "holiday.isCelebrated"/>
        </div>
    </div>
</div>
