import { app_type_for_clinic } from './Appointment_type_clinic'
import { WeekdayTermin } from './WeekdayTermin';
export class app_type_for_practitioner
{
    public id: number;
    public idPractitioner: number;
    public idAppTypeClinic: number;
    public appTypeClinic: app_type_for_clinic;

    public weekdayTermins: WeekdayTermin[] = [];
}