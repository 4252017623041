import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { WebApiService } from '../services/web-api.service';
import { practitioner } from '../model/Practitioner';
import { app_type_for_practitioner } from '../model/Appointment_type_practitioner';
import { TerminTime } from '../model/TerminTime'
import { FreeDaysAndTimes } from '../model/FreeDaysAndTimes'
import { patient } from '../model/Patient';
import * as moment from 'moment';
import { Appointment } from '../model/Appointment';





@Component({
  selector: 'app-add-appointment',
  templateUrl: './add-appointment.component.html',
  styleUrls: ['./add-appointment.component.scss']
})
export class AddAppointmentComponent implements OnInit {
  errorMessage: string = "";
  practitioners: practitioner[] = [];
  practAppTypes: app_type_for_practitioner[] = [];
  idClinic: number = Number(sessionStorage.getItem('idClinic'));
  selectedPractitionerId: number = 0;
  selectedAppTypeId: number = 0;
  selectedDate: string = "";
  freeTermins: TerminTime[] = [];
  patients: patient[] = [];
  selectedPatientId: number = 0;
  selectedTermin: TerminTime = null;
  isError: boolean = false;
  app: Appointment = new Appointment();
  isEditing: boolean = false;
  constructor(private webApiService: WebApiService,public matDialog:MatDialog, public dialogRef: MatDialogRef<AddAppointmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    {
      dialogRef.disableClose = true;
      if(data.app)
      {
        this.app = {...data.app};
        this.isEditing = true;
      }
    } 

  async ngOnInit() 
  {
    this.SetDefaultDate();
    await this.LoadPractitioners();
    await this.LoadPatients();
    if(this.isEditing)
    {
      this.SetEditingData();
    }
  }

  async SetEditingData()
  {
    this.practAppTypes = await this.webApiService.GetAllAppointmentTypesByPract(this.app.idPractitioner);
    this.selectedPractitionerId = this.app.idPractitioner;
    this.selectedAppTypeId = this.app.appointmentType.id;
    this.selectedDate = this.app.date.toString();
    await this.LoadNewTermin();
    let termin = new TerminTime();
    termin.from = this.app.timeFrom;
    termin.to = this.app.timeTo;
    this.freeTermins.push(termin);
    this.selectedTermin = termin;
    this.LoadPatients();
    this.selectedPatientId = this.app.patient.idPatient;
  }

  ConvertDateFormat(input: string)
  {
    let array = input.split('.');
    return array[2] + "-" + array[1] + "-" + array[0]; // convert u 2022-22-03 npe
  }

  CombineDateAndTime(date: string, time: string)
  {
    return date + 'T' + time;
  }
  async LoadPatients() 
  {
    this.patients = await this.webApiService.GetAllPatients(this.idClinic);
    this.patients.map(p => p.fullName = p.firstName + ' ' + p.lastName);
  }

  CloseModal()
  {
    this.dialogRef.close();
  }

  async LoadPractitioners()
  {
    this.practitioners = await this.webApiService.GetAllPractitioners(this.idClinic);
  }

  SetDefaultDate()
  {
    this.selectedDate = moment(new Date()).format("yyyy-MM-DD");
  }

  async SelectedPractChanged(value)
  {
    this.practAppTypes = [];
    this.freeTermins = [];
    this.selectedPractitionerId = Number(value.split(':')[1]);
    let pract: practitioner = this.practitioners.filter(p=> p.idPractitioner == this.selectedPractitionerId)[0];
    this.practAppTypes = await this.webApiService.GetAllAppointmentTypesByPract(pract.idPractitioner);
  }

  async SelectedAppTypeChanged(value)
  {
    this.selectedAppTypeId = Number(value.split(':')[1]);
    await this.LoadNewTermin();
  }

  async DateChanged(event)
  {
    await this.LoadNewTermin();
  }

  async LoadNewTermin()
  {
    this.freeTermins = [];
    let startDate = new Date(this.selectedDate);
    let endDate = new Date();
    endDate = this.AddDays(startDate,1);
    let startDatestr = moment(startDate).format("yyyy-MM-DD");
    let endDateStr = moment(endDate).format("yyyy-MM-DD");
    let ret:FreeDaysAndTimes[] = await this.webApiService.GetFreeDaysPerAppTypePerPeriodAsync(String(this.selectedAppTypeId),startDatestr,endDateStr);
    if(ret != null && ret.length > 0)
    {
      this.freeTermins = ret[0].times;
    }
  }

  AddDays(date: Date, days: number)
  {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  async InsertAppointment()
  {
    this.isError = false;
    let app: Appointment = new Appointment();
    if(this.ValidateInput())
    {
      app.idClinic = this.idClinic;
      app.idPractitioner = this.selectedPractitionerId;
      app.idPatient = this.selectedPatientId;
      app.timeFrom = this.selectedTermin.from;
      app.timeTo = this.selectedTermin.to;
      app.date = new Date(this.selectedDate.split('T')[0]);
      app.idAppType = this.selectedAppTypeId;
      app.timeOfBooking = new Date();
      app.createdOnline = true;
      app.idAppointmentStatus = 2;
      app.patient = this.patients.filter(p => p.idPatient == this.selectedPatientId)[0];
      if(!this.isEditing)
      {
        await this.webApiService.InsertOnlineAppointment(app);
      }
      else
      {
        app.idAppointment = this.app.idAppointment;
        let ret = await this.webApiService.UpdateOnlineAppointment(app);
      }
      this.dialogRef.close(true);
    }

  }

  ValidateInput()
  {
    if(this.selectedPractitionerId == 0 || this.selectedAppTypeId == 0 || this.selectedDate == "" 
       || this.selectedTermin == null ||this.selectedPatientId == 0 )
    {
      this.errorMessage = "Enter all fields with *";
      this.isError = true;
      return false;
    }
    return true;
  }

  SearchPatient(value: string,patient: patient)
  {
    let seacrchString = value.toUpperCase();
    let searchParts: string[] = seacrchString.split(' ');
    let flag
    searchParts.forEach(part => {
      if(patient.firstName.toUpperCase().includes(part)) //razmisliti da li koristiti includes ili startsWith
      {
        flag = true;
      }
      else if(patient.lastName.toUpperCase().includes(part))
      {
        flag = true;
      }
      else
      {
        flag = false;
        return false;
      }
    });
    return flag;
    
  }



  async onSelectedDateChanged(value)
  {
    this.selectedDate = value;
    await this.LoadNewTermin();
  }

}
