import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private configUrl = 'assets/config.json';
  private config: any;

  constructor(private http: HttpClient) { }

  loadConfig() {
    return this.http.get(this.configUrl).toPromise()
      .then((data) => {
        this.config = data;
      })
      .catch((error) => {
        console.error('Error loading config:', error);
      });
  }

  get root(): string {
    return this.config.root;
  }

  get rootClient(): string {
    return this.config.rootClient;
  }

}
