import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private timesResied:number = 0;

  constructor() { }

  private resized = new BehaviorSubject(false);
  IsResized = this.resized.asObservable();
 
  updateApprovalMessage(message: boolean) {
    this.resized.next(message)
  }
  
}
