import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-reason-modal',
  templateUrl: './reason-modal.component.html',
  styleUrls: ['./reason-modal.component.scss']
})
export class ReasonModalComponent implements OnInit {
  text: string = "";
  constructor(public matDialog:MatDialog, public dialogRef: MatDialogRef<ReasonModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any)  
    {
      dialogRef.disableClose = true;
    }

  ngOnInit(): void {
  }

  Confirm(flag: boolean)
  {
    if(flag == false)
    {
      this.dialogRef.close(false);
    }
    else
    {
      this.dialogRef.close(this.text);
    }
  }

}
