import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { WebApiService } from '../services/web-api.service';
import { practitioner } from '../model/Practitioner';
import { app_type_for_practitioner } from '../model/Appointment_type_practitioner';
import { TerminTime } from '../model/TerminTime'
import { DayTermin } from '../model/DayTermin'
import { WeekdayTermin } from '../model/WeekdayTermin';
import { app_type_for_clinic } from '../model/Appointment_type_clinic';
import * as moment from 'moment';
import { Theme } from '@fullcalendar/core';

@Component({
  selector: 'app-add-working-time',
  templateUrl: './add-working-time.component.html',
  styleUrls: ['./add-working-time.component.scss']
})
export class AddWorkingTimeComponent implements OnInit {
  idClinic: number = Number(sessionStorage.getItem('idClinic'));
  practitioners: practitioner[] = [];
  practAppTypes: app_type_for_practitioner[] = [];
  freeTermins: TerminTime[] = [];
  selectedPractitionerId: number = 0;
  selectedAppTypeId: number = 0;
  selectedDate: string;
  selectedTimeFrom: string = "";
  selectedTimeTo: string = "";
  isError: boolean = false;
  isEditing: boolean = false;
  selectedDayIndex: number = 0;
  weekDayTermin: WeekdayTermin = new WeekdayTermin();
  occupiedDayIndexes: number[] = [];
  dayTermins : DayTermin[] = [];
  startedProcessing: boolean = false;
  appTypes: app_type_for_clinic[] = [];
  weekdayTerminToEdit: WeekdayTermin = null;
  idWeekDay: number = 0;
  constructor(private webApiService: WebApiService,public matDialog:MatDialog, public dialogRef: MatDialogRef<AddWorkingTimeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    {
      dialogRef.disableClose = true;
      this.occupiedDayIndexes = data.occupiedDayIndexes;
      this.selectedPractitionerId = data.idSelectedPractitioner;
      this.selectedAppTypeId = data.idSelectedAppType;
      this.weekdayTerminToEdit = data.weekdayTerminToEdit;
      this.idWeekDay = data.idWeekDay;
      if(this.weekdayTerminToEdit)
      {
        this.isEditing = true;
        this.SetEditData();
      }
      else
      {
        this.isEditing = false;
      }
    } 

  async ngOnInit()
  {
    this.SetDefaultDate();
    await this.LoadPractitioners();
    await this.LoadAppointmentTypes();
  }

  async LoadAppointmentTypes()
  {
    this.appTypes = await this.webApiService.GetAllAppointmentTypesByClinic();
  }

  SetEditData()
  {
    this.weekdayTerminToEdit.termins.forEach(termin => {
      let ter: DayTermin = new DayTermin();
      ter.to = termin.to.slice(0,5);
      ter.from = termin.from.slice(0,5);
      this.dayTermins.push(ter);
    });
  }
  async SelectedPractChanged(value)
  {
    this.practAppTypes = [];
    this.freeTermins = [];
    this.selectedPractitionerId = Number(value.split(':')[1]);
    await this.LoadPractAppTypes();
  }

  async LoadPractAppTypes()
  {
    let pract: practitioner = this.practitioners.filter(p=> p.idPractitioner == this.selectedPractitionerId)[0];
    this.practAppTypes = await this.webApiService.GetAllAppointmentTypesByPract(pract.idPractitioner);
  }

  async SelectedAppTypeChanged(value)
  {
    this.selectedAppTypeId = Number(value.split(':')[1]);
  }

  async LoadPractitioners()
  {
    this.practitioners = await this.webApiService.GetAllPractitioners(this.idClinic);
    await this.LoadPractAppTypes();
  }

  async onSelectedDateChanged(value)
  {
    this.selectedDate = value;;
  }

  SetDefaultDate()
  {
    this.selectedDate = moment(new Date()).format("yyyy-MM-DD");
  }

  CloseModal()
  {
    this.dialogRef.close(false);
  }

  async AddNewTermin()
  {
    if(this.CheckIfIntervalCorrect() && this.CheckIfIntervalOverlap())
    {
      let termin: DayTermin = new DayTermin();
      termin.from = this.selectedTimeFrom;
      termin.to = this.selectedTimeTo;
      this.dayTermins.push(termin);
      await this.InsertNewWeekDayWorkingTime();
      //this.dialogRef.close(true);
    }
  }

  CheckIfIntervalCorrect()
  {
    var from = moment(this.selectedTimeFrom,'hh:mm');
    var to = moment(this.selectedTimeTo,'hh:mm');
    if(from.isBefore(to))
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  CheckIfIntervalOverlap()
  {
    let newFrom = moment(this.selectedTimeFrom,'hh:mm');
    let newTo = moment(this.selectedTimeTo,'hh:mm');
    let ret = true;
    this.dayTermins.forEach(element => {
      let from = moment(element.from,'hh:mm');
      let to = moment(element.to,'hh:mm');
      if(newFrom.isSameOrAfter(from) && newFrom.isBefore(to))
      {
        ret = false;
      }
      if(newTo.isAfter(from) && newTo.isSameOrBefore(to))
      {
        ret = false;
      }
    });
    return ret;
  }

  TimeFromSelected(event)
  {
    let duration: number = this.appTypes.filter(p => p.id == this.selectedAppTypeId)[0].duration;
    this.selectedTimeFrom = event;
    this.selectedTimeTo = moment(this.selectedTimeFrom, 'HH:mm:ss').add(duration, 'minutes').format('HH:mm');
  }

  RemoveTermin(termin: DayTermin)
  {
    this.dayTermins.splice(this.dayTermins.indexOf(termin),1);
  }

  async InsertNewWeekDayWorkingTime()
  {
      if(!this.isEditing)
      {
        this.weekDayTermin.idWeekDay = this.idWeekDay;
        this.weekDayTermin.idAppTypeForPractitioner = 0;
        this.weekDayTermin.termins = [];
        this.startedProcessing = true;
        this.dayTermins.forEach(element => {
          element.from = element.from + ":00";
          element.to = element.to + ":00"
          this.weekDayTermin.termins.push(element);
        });
        let ret = await this.webApiService.InsertAppTypeTerminsByPract(this.selectedPractitionerId,this.selectedAppTypeId,this.weekDayTermin);
        let id = ret['idAppTypeForPractitioner'];
        this.dialogRef.close(id);

      }
      else
      {
        this.weekdayTerminToEdit.termins = [];
        this.startedProcessing = true;
        this.dayTermins.forEach(element => {
          element.from = element.from + ":00";
          element.to = element.to + ":00"
          this.weekdayTerminToEdit.termins.push(element);
        });
        let ret = await this.webApiService.UpdateAppTypeTerminsByPract(this.weekdayTerminToEdit);
        this.dialogRef.close(this.weekdayTerminToEdit.idAppTypeForPractitioner);

      }
  }

  IsInArray(dayIndex: number)
  {
    if(this.occupiedDayIndexes.includes(dayIndex))
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  ValidateInput()
  {
    if(!this.isEditing)
    {
      if(this.selectedDayIndex != 0 && this.dayTermins.length > 0)
      {
        this.isError = false;
        return true;
      }
      else
      {
        this.isError = true;
        return false;
      }
    }
    else
    {
      if(this.selectedDayIndex != 0)
      {
        this.isError = false;
        return true;
      }
      else
      {
        this.isError = true;
        return false;
      }
    }
  }

}
