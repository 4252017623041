import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Clinic } from '../model/Clinic'
import { Appointment } from '../model/Appointment';
import { clinic_user } from '../model/Clinic_User';
import { practitioner } from '../model/Practitioner';
import { block_appointment } from '../model/BlockAppointment';
import { app_type_for_clinic } from '../model/Appointment_type_clinic'
import { color } from '../model/Color';
import { smsConfig } from '../model/SmsConfig';
import { WeekdayTermin } from '../model/WeekdayTermin';
import { Company } from '../model/Company';
import { ExcelFile } from '../model/ExcelFile';
import { clinic_holiday } from '../model/clinic_holiday';
import { Canton } from '../model/Canton';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class WebApiService {
  //rootClient: string = "http://testoaclient.easydentlink.ch";
  //root: string = "http://testoaclient.easydentlink.ch/OAWebApi";
  //rootClient: string = "https://clientoa.easydentweb.ch";
  //root: string = "https://clientoa.easydentweb.ch/OAWebApi";

  rootClient = this.configService.rootClient;
  root = this.configService.root;

  constructor(private httpClient: HttpClient, public configService: ConfigService) { }

  GetAgendaClientPath() {
    return this.rootClient;
  }

  async GetAllAppointments(idClinic: number, DateFrom: string, DateTo: string) {
    let asyncResult = this.httpClient.get<any>(`${this.root}/api/appointment/GetByIdClinic/${DateFrom}/${DateTo}`).toPromise();
    return asyncResult;
  }

  async GetAllCreatedOnlineAppointments(idClinic: number, DateFrom: string, DateTo: string) {
    let asyncResult = this.httpClient.get<any>(`${this.root}/api/appointment/GetByIdClinicCreatedOnline/${DateFrom}/${DateTo}`).toPromise();
    return asyncResult;
  }

  async GetBlockedAppointmentsByPract(idPractitioner: number, startDate: string, endDate: string) {
    let asyncResult = this.httpClient.get<any>(`${this.root}/api/BlockAppointment/GetByPractitioner/${idPractitioner}/${startDate}/${endDate}`).toPromise();
    return asyncResult;
  }

  async GetBlockedAppointmentsByClinic(idClinic: number, startDate: string, endDate: string) {
    let asyncResult = this.httpClient.get<any>(`${this.root}/api/BlockAppointment/getbyclinic/${startDate}/${endDate}`).toPromise();
    return asyncResult;
  }

  async GetPendingByClinic(idClinic: number) {
    let asyncResult = this.httpClient.get<any>(`${this.root}/api/appointment/GetPendingByIdClinic`).toPromise();
    return asyncResult;
  }

  async InsertBlockedAppointment(app: block_appointment) {
    let asyncResult = this.httpClient.post<any>(`${this.root}/api/BlockAppointment/Insert/`, app).toPromise();
    return asyncResult;
  }

  async DeleteBlockedAppointment(app: block_appointment) {
    let asyncResult = this.httpClient.post<any>(`${this.root}/api/BlockAppointment/Delete/`, app).toPromise();
    return asyncResult;
  }

  async InsertAppointmentType(app: app_type_for_clinic) {
    let asyncResult = this.httpClient.post<any>(`${this.root}/api/AppointmentType/InsertAppTypeClinic/`, app).toPromise();
    return asyncResult;
  }

  async DeleteAppointmentType(app: app_type_for_clinic) {
    let asyncResult = this.httpClient.post<any>(`${this.root}/api/AppointmentType/DeleteAppTypeClinic/`, app).toPromise();
    return asyncResult;
  }

  async UpdateAppointmentType(app: app_type_for_clinic) {
    let asyncResult = this.httpClient.post<any>(`${this.root}/api/AppointmentType/UpdateAppTypeClinic/`, app).toPromise();
    return asyncResult;
  }

  async UpdateBlockedAppointment(app: block_appointment) {
    let asyncResult = this.httpClient.post<any>(`${this.root}/api/BlockAppointment/Update/`, app).toPromise();
    return asyncResult;
  }


  async GetAllAppointmentTypesByPract(idPractitioner: number) {
    let asyncResult = this.httpClient.get<any>(`${this.root}/api/AppointmentType/GetAppointmentTypeByPractitioner/${idPractitioner}`).toPromise();
    return asyncResult;
  }

  async GetAllAppointmentTypesByClinic() {
    let asyncResult = this.httpClient.get<any>(`${this.root}/api/AppointmentType/GetAppointmentTypeByClinic`).toPromise();
    return asyncResult;
  }


  async GetAllPractitioners(idClinic: number) {
    let asyncResult = this.httpClient.get<any>(`${this.root}/api/practitioner/GetAllPractitionersByClinic/${idClinic}`).toPromise();
    return asyncResult;
  }

  async GetAllPatients(idClinic: number) {
    let asyncResult = this.httpClient.get<any>(`${this.root}/api/patient/GetPatientsByClinic`).toPromise();
    return asyncResult;
  }

  async GetFreeDaysPerAppTypePerPeriodAsync(appTypeId: string, start: string, end: string) {
    let asyncResult = await this.httpClient.get<any>(`${this.root}/api/AppointmentTypePractWeek/GetFreeDaysPerAppTypePerPeriod/${appTypeId}/${start}/${end}`).toPromise();
    return asyncResult;
  }

  async GetClinicByName(name: string) {
    let asyncResult = this.httpClient.get<any>(`${this.root}/api/clinic/GetClinicByName/${name}`,).toPromise();
    return asyncResult;
  }

  async GetClinicById() {
    let asyncResult = this.httpClient.get<any>(`${this.root}/api/clinic/GetClinicById`,).toPromise();
    return asyncResult;
  }

  async GetAllAppStatuses() {
    let asyncResult = this.httpClient.get<any>(`${this.root}/api/appointment/GetAllStatus`,).toPromise();
    return asyncResult;
  }

  async GetAllPatientsByClinic(idClinic: number) {
    let asyncResult = this.httpClient.get<any>(`${this.root}/api/patient/getpatientsbyclinic/${idClinic}`,).toPromise();
    return asyncResult;
  }

  async InsertOnlineAppointment(app: Appointment) {
    let asyncResult = this.httpClient.post(`${this.root}/api/appointment/InsertManual/`, app).toPromise();
    return asyncResult;
  }

  async UpdateOnlineAppointment(app: Appointment) {
    let asyncResult = this.httpClient.post(`${this.root}/api/appointment/Update/`, app).toPromise();
    return asyncResult;
  }

  async DeleteAppointment(app: Appointment) {
    let asyncResult = this.httpClient.post(`${this.root}/api/appointment/Delete/`, app).toPromise();
    return asyncResult;
  }

  async InsertNewPractitioner(pract: practitioner) {
    let asyncResult = this.httpClient.post(`${this.root}/api/practitioner/Insert/`, pract).toPromise();
    return asyncResult;
  }

  async UpdatePractitioner(pract: practitioner) {
    let asyncResult = this.httpClient.post(`${this.root}/api/practitioner/Update/`, pract).toPromise();
    return asyncResult;
  }

  async DeletePractitioner(pract: practitioner) {
    let asyncResult = this.httpClient.post(`${this.root}/api/practitioner/Delete/`, pract).toPromise();
    return asyncResult;
  }

  async InsertClinicUser(user: clinic_user) {
    let asyncResult = this.httpClient.post(`${this.root}/api/ClinicUser/Insert/`, user).toPromise();
    return asyncResult;
  }
  async DeleteClinicUser(user: clinic_user) {
    let asyncResult = this.httpClient.post(`${this.root}/api/ClinicUser/Delete/`, user).toPromise();
    return asyncResult;
  }

  async UpdateClinicUser(user: clinic_user) {
    let asyncResult = this.httpClient.post(`${this.root}/api/ClinicUser/Update/`, user).toPromise();
    return asyncResult;
  }

  async LoginUser(user: clinic_user) {
    console.log(this.root);
    let asyncResult = this.httpClient.post(`${this.root}/api/clinic/login/`, user).toPromise();
    return asyncResult;
  }

  async GetClinicUsers(idClinic: number) {
    let asyncResult = this.httpClient.get<any>(`${this.root}/api/ClinicUser/GetClinicUserByClinic`,).toPromise();
    return asyncResult;
  }

  //http://testoaclient.easydentlink.ch/OAWebApi/api/practitioner/uploadimage/171

  async UploadPractImage(idPract: number, fd: FormData) {
    let asyncResult = this.httpClient.post(`${this.root}/api/practitioner/uploadimage/${idPract}`, fd).toPromise();
    return asyncResult;
  }

  async UploadPatients(data: ExcelFile) {
    let asyncResult = this.httpClient.post(`${this.root}/api/patient/UploadPatients`, data).toPromise();
    return asyncResult;
  }

  async UploadLogoImage(fd: FormData) {
    let asyncResult = this.httpClient.post(`${this.root}/api/clinic/UploadLogo/`, fd).toPromise();
    return asyncResult;
  }

  async UploadCompanyLogo(fd: FormData) {
    let asyncResult = this.httpClient.post(`${this.root}/api/clinic/UploadLogoCompany/`, fd).toPromise();
    return asyncResult;
  }

  async UpdateClinicColor(color: color) {
    let asyncResult = this.httpClient.post(`${this.root}/api/clinic/updateColor/`, color).toPromise();
    return asyncResult;
  }

  async GetClinicRoles() {
    let asyncResult = this.httpClient.get<any>(`${this.root}/api/ClinicUser/GetClinicRoles`,).toPromise();
    return asyncResult;
  }

  async GetSMSLogByClinic() {
    let asyncResult = this.httpClient.get<any>(`${this.root}/api/SMS/GetSMSLogByClinic`,).toPromise();
    return asyncResult;
  }

  async GetSMSConfigByClinic() {
    let asyncResult = this.httpClient.get<any>(`${this.root}/api/SMS/GetSMSConfigByClinic`,).toPromise();
    return asyncResult;
  }

  async InsertSMSConfigByClinic(config: smsConfig) {
    let asyncResult = this.httpClient.post(`${this.root}/api/SMS/InsertSmsConfig`, config).toPromise();
    return asyncResult;
  }

  async UpdateSMSConfigByClinic(config: smsConfig) {
    let asyncResult = this.httpClient.post(`${this.root}/api/SMS/UpdateSmsConfig`, config).toPromise();
    return asyncResult;
  }

  async UpdateClinic(clinic: Clinic) {
    let asyncResult = this.httpClient.post(`${this.root}/api/clinic/update/`, clinic).toPromise();
    return asyncResult;
  }

  async CheckCredits() {
    let asyncResult = this.httpClient.get(`${this.root}/api/SMS/CheckCredit`, { responseType: "text" }).toPromise();
    return asyncResult;
  }

  async GetAppTypeTerminsByPract(app_type_pract_id: number) {
    let asyncResult = this.httpClient.get<any>(`${this.root}/api/AppointmentTypePractWeek/GetAllByIdAppType/${app_type_pract_id}`).toPromise();
    return asyncResult;
  }

  async InsertAppTypeTerminsByPract(idPractitioner, idAppType, termin: WeekdayTermin) {
    let asyncResult = this.httpClient.post(`${this.root}/api/AppointmentTypePractWeek/Insert/${idPractitioner}/${idAppType}`, termin).toPromise();
    return asyncResult;
  }

  async UpdateAppTypeTerminsByPract(termin: WeekdayTermin) {
    let asyncResult = this.httpClient.post(`${this.root}/api/AppointmentTypePractWeek/Update/`, termin).toPromise();
    return asyncResult;
  }

  async DeleteAppTypeTerminsByPract(termin: WeekdayTermin) {
    let asyncResult = this.httpClient.post(`${this.root}/api/AppointmentTypePractWeek/Delete/`, termin).toPromise();
    return asyncResult;
  }

  async GetCompanyById(companyId: number) {
    let asyncResult = this.httpClient.get<any>(`${this.root}/api/clinic/GetCompanyById/${companyId}`).toPromise();
    return asyncResult;
  }

  async UpdateCompany(company: Company) {
    let asyncResult = this.httpClient.post(`${this.root}/api/clinic/updatecompany/`, company).toPromise();
    return asyncResult;
  }

  async ForgotPassword(user: clinic_user) {
    let asyncResult = this.httpClient.post(`${this.root}/api/clinic/PaswordRecovery/`, user).toPromise();
    return asyncResult;
  }

  async GetHolidaysByClinic(idClinic: number) {
    let asyncResult = this.httpClient.get<any>(`${this.root}/api/clinic/GetHolidaysByClinic/${idClinic}`).toPromise();
    return asyncResult;
  }

  async UpdateHoliday(holiday: clinic_holiday) {
    let asyncResult = this.httpClient.post<any>(`${this.root}/api/clinic/UpdateCelebrationOgHoliday`, holiday).toPromise();
    return asyncResult;
  }

  async GetAllCantons(): Promise<Canton[]> {
    let asyncResult = this.httpClient.get<any>(`${this.root}/api/clinic/GetCantons`).toPromise();
    return asyncResult;
  }

  async UpdateClinicCanton(clinic: Clinic) {
    let asyncResult = this.httpClient.post<any>(`${this.root}/api/clinic/UpdateCanton`, clinic).toPromise();
    return asyncResult;
  }


}
