import { Component, OnInit } from '@angular/core';
import { app_type_for_practitioner } from '../model/Appointment_type_practitioner';
import { app_type_for_clinic } from '../model/Appointment_type_clinic';
import { WebApiService } from '../services/web-api.service';
import { MatDialog } from "@angular/material/dialog";
import { AddAppointmentTypeComponent } from '../add-appointment-type/add-appointment-type.component';
import { QuestionModalComponent } from '../question-modal/question-modal.component';
import { InfoModalComponent } from '../info-modal/info-modal.component';

@Component({
  selector: 'app-appointment-types',
  templateUrl: './appointment-types.component.html',
  styleUrls: ['./appointment-types.component.scss']
})
export class AppointmentTypesComponent implements OnInit {
  idClinic: number = Number(sessionStorage.getItem('idClinic'));
  appTypes: app_type_for_clinic[] = [];
  appTypeToEdit: app_type_for_clinic = null;
  constructor(private webApiService: WebApiService, private matDialog: MatDialog) { }

  async ngOnInit() 
  {
    this.LoadAppointmentTypes();
  }

  async LoadAppointmentTypes()
  {
    this.appTypes = await this.webApiService.GetAllAppointmentTypesByClinic();
  }

  OpenAddNewAppType()
  {
    let dialogRef = this.matDialog.open(AddAppointmentTypeComponent,{
      panelClass: 'dialog-class',
      data: {
        appType: this.appTypeToEdit
      },
      height: "auto",
      width: "600px"
    });    
    dialogRef.afterClosed().subscribe(async result => {
      if (result == true) 
      {
        window.location.reload();
      }
    })
  }

  OpenEditAppType(appType: app_type_for_clinic)
  {
    this.appTypeToEdit = appType;
    this.OpenAddNewAppType();
  }

  async Delete(appType: app_type_for_clinic)
  {
    let dialogRef = this.matDialog.open(QuestionModalComponent,{
      panelClass: 'dialog-class',
      data: {
        question:"Sind Sie sicher, dass Sie die ausgewählte Terminart löschen möchten?"
      },
      height: "auto",
    });    
    dialogRef.afterClosed().subscribe(async result => {
      if (result == true) 
      {
        let ret = await this.webApiService.DeleteAppointmentType(appType);
        if(ret == true)
        {
          window.location.reload();
        }
        else
        {
          this.ShowInfo("Terminart hat Termine und kann nicht gelöscht werden.")
        }
      }
    })
  }

  ShowInfo(message: string)
  {
    let dialogRef = this.matDialog.open(InfoModalComponent,{
      panelClass: 'dialog-class',
      data: {
        question: message
      },
      height: "auto",
    });    
  }

  AddNewAppType()
  {
    this.appTypeToEdit = null;
    this.OpenAddNewAppType();
  }

}
