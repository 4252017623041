import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { clinic_user } from '../model/Clinic_User'
import { WebApiService } from '../services/web-api.service';
import jwt_decode from 'jwt-decode';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  errorMessage: string = "";
  user: clinic_user = new clinic_user();
  isError: boolean = false;
  decodedToken: string = "";
  constructor(public router: Router, 
              public webApiService: WebApiService,
              private matDialog: MatDialog) { }

  ngOnInit(): void {
  }



  async LogIn() {
    if (this.Validate()) {
      let token = await this.webApiService.LoginUser(this.user);
      if (token != null) {
        sessionStorage.setItem('token', token['token']);
        this.decodedToken = this.getDecodedAccessToken(token['token']);
        if (this.decodedToken['Role'] == "Synchronization") {
          this.errorMessage = "This user cannot use Online agenda admin."
          this.isError = true;
          return;
        }
        sessionStorage.setItem('idClinic', this.decodedToken['IdClinic'])
        sessionStorage.setItem('userRole', this.decodedToken['Role'])
        this.router.navigateByUrl('home/agenda');
      }
      else {
        this.errorMessage = "Falsch Benutzername, Passwort or Eindeutige ID!"
        this.isError = true;
      }
    }
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }

  Validate() {
    if (this.user.clinicUID != "" && this.user.username != "" && this.user.password != "")
      return true;

    this.errorMessage = "Füllen Sie alle Felder aus!";
    this.isError = true;
    return false;

  }

  async ForgotPasswordClicked() {
    let ret: any = await this.webApiService.ForgotPassword(this.user);
    if (ret == true)
    {
      this.ShowInfo("Das Passwort wurde erfolgreich geändert und an Ihre E-Mail gesendet.");
    }
    else
    {
      this.ShowInfo("Passwort konnte nicht erfolgreich geändert werden.");
    }
  }

  ShowInfo(message: string)
  {
    let dialogRef = this.matDialog.open(InfoModalComponent,{
      panelClass: 'dialog-class',
      data: {
        question: message
      },
      height: "auto",
    });    
  }

}
