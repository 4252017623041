import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { AddBlockedAppointmentComponent } from '../add-blocked-appointment/add-blocked-appointment.component';
import { WebApiService } from '../services/web-api.service';
import * as moment from 'moment';
import { block_appointment } from '../model/BlockAppointment';
import { QuestionModalComponent } from '../question-modal/question-modal.component';

import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { practitioner } from '../model/Practitioner';

@Component({
  selector: 'app-blocked-appointments',
  templateUrl: './blocked-appointments.component.html',
  styleUrls: ['./blocked-appointments.component.scss']
})
export class BlockedAppointmentsComponent implements OnInit {
  startDate: Date = new Date();
  endDate: Date = new Date();
  monthStr: string = "";
  idClinic: number = Number(sessionStorage.getItem('idClinic'));
  blockedApps: block_appointment[] = [];
  blockedAppsFiltered: block_appointment[] = [];
  appToEdit: block_appointment;
  practitioners: practitioner[] = [];
  idSelectedPractitioner: number = 0;
  constructor(private matDialog: MatDialog,private webApiService:WebApiService) { }
  @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;

  async ngOnInit() {
    this.SetStartingDates();
    await this.LoadPractitioners();
    await this.LoadBlockedAppointments(this.startDate,this.endDate);
  }

  async LoadPractitioners()
  {
    this.practitioners = await this.webApiService.GetAllPractitioners(this.idClinic);
    if(sessionStorage.getItem('idSelectedPractitioner'))
    {
      this.idSelectedPractitioner = Number(sessionStorage.getItem('idSelectedPractitioner'));
    }
    else
    {
      this.idSelectedPractitioner = this.practitioners[0].idPractitioner;
    }
  }


  FilterBlockedAppointments()
  {
    this.blockedAppsFiltered = [];
    this.blockedAppsFiltered = this.blockedApps.filter(a => a.idPractitioner == this.idSelectedPractitioner);
  }

  OpenAddBlockedAppointmentModal()
  {
    let dialogRef = this.matDialog.open(AddBlockedAppointmentComponent,{
      panelClass: 'dialog-class',
      data: {
        app:this.appToEdit,
        idPract: this.idSelectedPractitioner 
      },
      height: "auto",
      width: "600px"
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result == true) 
      {
        window.location.reload();
      }
    })
  }

  async LoadBlockedAppointments(startDate: Date, endDate: Date)
  {
    this.blockedApps = [];
    this.blockedApps = await this.webApiService.GetBlockedAppointmentsByClinic(this.idClinic,this.startDate.toISOString(), this.endDate.toISOString())
    this.FilterBlockedAppointments(); 
  }

  SetStartingDates()
  {
    let date = new Date();
    this.startDate = this.GetFirstDayOfMonth(date);
    this.endDate = this.GetDatePlusTenYears(date);
  }

  GetFirstDayOfMonth(date: Date)
  {
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    this.monthStr = moment(firstDay).format("MMMM-YYYY");
    firstDay.setDate(firstDay.getDate() - 1);
    return firstDay;
  }

  GetDatePlusTenYears(date: Date)
  {
    let lastDay = new Date(date.getFullYear() + 10, date.getMonth() + 1, 0);
    lastDay.setDate(lastDay.getDate() + 1);
    return lastDay;
  }

  /*GoMonthForward()
  {
    let date = this.AddDays(this.endDate,10);
    this.startDate = this.GetFirstDayOfMonth(date);
    this.endDate = this.GetLastDayOfMonth(date);
    this.LoadBlockedAppointments(this.startDate,this.endDate);  
  }

  GoMonthBackward()
  {
    let date = this.AddDays(this.startDate, -10)
    this.startDate = this.GetFirstDayOfMonth(date);
    this.endDate = this.GetLastDayOfMonth(date);
    this.LoadBlockedAppointments(this.startDate,this.endDate);  
  }

  GoToToday()
  {
    let date = new Date();
    this.startDate = this.GetFirstDayOfMonth(date);
    this.endDate = this.GetLastDayOfMonth(date);
    this.LoadBlockedAppointments(this.startDate,this.endDate);  
  }*/

  AddDays(date, days) 
  {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  EditSelectedBlockedAppointment(app:block_appointment)
  {
    this.appToEdit = app;
    this.OpenAddBlockedAppointmentModal();
  }

  AddNewBlockedAppointment()
  {
    this.appToEdit = null;
    this.OpenAddBlockedAppointmentModal();
  }

  DownloadPdf()
  {
    const doc = new jsPDF();
    let name = this.practitioners.filter(p => p.idPractitioner == this.idSelectedPractitioner)[0]?.name;
    if(name == null)
    {
      name = "All practitioners";
    }
    doc.text(name, 14, 25);
    autoTable(doc, {
      columnStyles:
      {
        0: { minCellWidth: 40},
        1: { minCellWidth: 40},
        2: { minCellWidth: 40},
        3: { minCellWidth: 40},
        4: { minCellWidth: 0, cellWidth:0},
      },
      margin:{top:30},
      html: '#pdfTable'});

    doc.save('BlockedAppointments.pdf');
  }

  async SelectedPractChanged(value: string)
  {
    this.idSelectedPractitioner = Number(value.split(":")[1]);
    if(this.idSelectedPractitioner == -1)
    {
      this.blockedAppsFiltered = [];
      this.blockedAppsFiltered = this.blockedApps.filter(a => true);
    }
    else
    {
      this.FilterBlockedAppointments();
    }
  }

  async Delete(app: block_appointment)
  {
    let dialogRef = this.matDialog.open(QuestionModalComponent,{
      panelClass: 'dialog-class',
      data: {
        question:"Are you sure that you want to delete selected blocked appointment?"
      },
      height: "auto",
    });    
    dialogRef.afterClosed().subscribe(async result => {
      if (result == true) 
      {
        await this.webApiService.DeleteBlockedAppointment(app);
        window.location.reload();
      }
    })
  }

}
