<div>
    <div class="header">
        Termin hinzufügen
    </div>
    <div class="form">
        <div class="field-label">
            Behandler*
        </div>
        <select (input)="SelectedPractChanged($event.target.value)" [(ngModel)]="selectedPractitionerId" class="select_control">
            <option [ngValue] = "0" value="" disabled selected>Select your practitioner</option>
            <option [ngValue] = "pract.idPractitioner" *ngFor = "let pract of practitioners">{{pract.name}}</option>
        </select>
        <div class="field-label">
            Terminart*
        </div>
        <select (input)="SelectedAppTypeChanged($event.target.value)" [(ngModel)]="selectedAppTypeId" class="select_control">
            <option [ngValue] = "0" disabled selected>Terminart auswählen</option>
            <option [ngValue] = "appType.id" *ngFor = "let appType of practAppTypes">{{appType.appTypeClinic.descriptionDe}}</option>
        </select>


        <div class="field-label">
            Datum*
        </div>
        <!--<input (change)="DateChanged($event)" type="date" id="datepicker" name="date"  [(ngModel)]  = "selectedDate" class="input_field"/>-->
        <app-date-picker [pickerWidth] = "'none'" (dateChange)="onSelectedDateChanged($event)" [selectedDate]="selectedDate"></app-date-picker>

        <div style="margin-top: 10px;" class="field-label">
            Mal*
        </div>
        <select [(ngModel)]="selectedTermin" class="select_control">
            <option [ngValue] = "null" disabled selected>Termin auswählen</option>
            <option [ngValue] = "termin" *ngFor = "let termin of freeTermins">{{termin.from | dateToTime}} - {{termin.to | dateToTime}}</option>
        </select>

        <div class="field-label">
            Geduldig*
        </div>
        <div class="add_select_wrapper">
            <ng-select class="custom_ngselect"  appendTo="body"  [items]="patients"
                [searchFn]="SearchPatient"
                [virtualScroll]="true"
                bindLabel="fullName"
                bindValue="idPatient"
                [(ngModel)] = "selectedPatientId"
                #select 
                (change)="select.blur()"
                (focus)="select.blur()"
                (clear)="select.blur()">
            </ng-select>
            <div class="add_patient_div">
                <i class='bx bx-plus-medical add_patient_img'></i>
            </div>
        </div>
        <div style="height: 35px;">
            <div *ngIf = "isError" class="error">
                {{errorMessage}}
            </div>
        </div>
    </div>
    <div class="close_modal">
        <button (click) = "CloseModal()" class="btn_close">
            Abbruch
        </button>
        <button (click) = "InsertAppointment()" class="btn_confirm">
            Einreichen
        </button>
    </div>
</div>
