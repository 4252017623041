import { Component, OnInit, HostBinding,Inject } from '@angular/core';
import { Clinic } from '../model/Clinic';
import { color } from '../model/Color'
import { WebApiService } from '../services/web-api.service';

@Component({
  selector: 'app-edit-colours',
  templateUrl: './edit-colours.component.html',
  styleUrls: ['./edit-colours.component.scss']
})
export class EditColoursComponent implements OnInit {
  calendarTop: string =  "#9ced7a";
  calendarDays: string =  "#69c194";
  menu: string =  "#69c194";
  chooseAppointment: string =  "#3c78d8";
  appointmentType: string =  "#61C2AB";

  color: color = new color();
  clinic: Clinic = new Clinic();
  clinicName: string = "demo";
  idClinic: number = Number(sessionStorage.getItem('idClinic'));

  constructor(private webApiService: WebApiService){}


  ngOnInit(): void {
    this.LoadColors();
  }

  CloseModal()
  {
    //this.dialogRef.close()
  }

  async Save()
  {
    let ret = await this.webApiService.UpdateClinicColor(this.color);
    window.location.reload();
  }



  async LoadColors()
  {
    this.clinic = await this.webApiService.GetClinicById();
    this.color = this.clinic.color;
  }

  GetPath()
  {
    return this.webApiService.GetAgendaClientPath();
  }


}
