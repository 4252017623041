import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { DateAdapter,MAT_DATE_FORMATS,MAT_DATE_LOCALE} from '@angular/material/core';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';


export const MY_FORMATS = {
  parse: {
      dateInput: 'LL'
  },
  display: {
      dateInput: 'DD.MM.yyyy',
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DatePickerComponent implements OnInit {
  @Input() selectedDate: string;
  @Input() pickerWidth: string;
  @Output() dateChange = new EventEmitter();
  //selectedDate:string = "";
  constructor() { }

  ngOnInit(){
    this.SetStyles();
  }

  SetStyles()
  {
    document.documentElement.style.setProperty('--pickerWidth', this.pickerWidth);
  }

  SelectedDateChanged(event)
  {
    let dateStr : string = moment(this.selectedDate).format("yyyy-MM-DD");
    this.dateChange.emit(dateStr);
  }

  clearDate(event) {
    event.stopPropagation();
    this.selectedDate = "";
    this.dateChange.emit(this.selectedDate);
  }

}
