<div class="content">
    <div class="header">
        Gesperrten Termin hinzufügen
    </div>
    <div class="form">
        <div class="field-label">
            Behandler*
        </div>
        <select [(ngModel)]="selectedPractitionerId" class="select_control">
            <option [ngValue] = "0" value="" disabled selected>Select your practitioner</option>
            <option [ngValue] = "pract.idPractitioner" *ngFor = "let pract of practitioners">{{pract.name}}</option>
        </select>
        <div class="field-label">
            Datum von*
        </div>
        <!--<input (change)="DateChanged($event)" type="date" id="datepicker" name="date" [(ngModel)] = "selectedDateFrom" class="input_field"/>-->
        <app-date-picker [pickerWidth] = "'none'" (dateChange)="SelectedDateFromChanged($event)" [selectedDate]="selectedDateFrom"></app-date-picker>

        <div style="margin-top: 10px;" class="field-label">
            Zeit von*
        </div>
        <!--<input (change)="DateChanged($event)" type="time" id="datepicker" step="900" name="Time" [(ngModel)] = "selectedTimeFrom" class="input_field"/>-->
        <div class="time-div">
            <input [(ngModel)] = "selectedTimeFrom" [format]="24" [ngxTimepicker]="picker1" class="input_field time-input">
            <ngx-material-timepicker-toggle class="time-input-button"  [for]="picker1"></ngx-material-timepicker-toggle>
            <ngx-material-timepicker [minutesGap]="15" #picker1></ngx-material-timepicker>
        </div>



        <div class="field-label">
            Datum bis*
        </div>
        <!--<input (change)="DateChanged($event)" type="date" id="datepicker" name="date" [(ngModel)] = "selectedDateTo" class="input_field"/>-->
        <app-date-picker [pickerWidth] = "'none'" (dateChange)="SelectedDateToChanged($event)" [selectedDate]="selectedDateTo"></app-date-picker>

        <div style="margin-top: 10px;" class="field-label">
            Zeit bis*
        </div>
        <!--<input (change)="DateChanged($event)" type="time" id="datepicker" step="900" name="Time" [(ngModel)] = "selectedTimeTo" class="input_field"/>-->
        <div class="time-div">
            <input [(ngModel)] = "selectedTimeTo" [format]="24" [ngxTimepicker]="picker2" class="input_field time-input">
            <ngx-material-timepicker-toggle class="time-input-button"  [for]="picker2"></ngx-material-timepicker-toggle>
            <ngx-material-timepicker [minutesGap]="15" #picker2></ngx-material-timepicker>
        </div>

        <div class="field-label">
            Text
        </div>
        <textarea style="margin-bottom: 0px;font-size: 16px;" (change)="DateChanged($event)" type="text" id="datepicker" cdkTextareaAutosize  [(ngModel)] = "text" class="input_field text_area"></textarea>
        <div style="height: 35px;">
            <div *ngIf = "isError" class="error">
                {{errorMessage}}
            </div>
        </div>
    </div>
    <div class="close_modal">
        <button (click) = "CloseModal()" class="btn_close">
            Abbruch
        </button>
        <button (click) = "InsertBlockedAppointment()" class="btn_confirm">
            Speichern
        </button>
    </div>
</div>
