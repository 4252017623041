<div class="container">
	<div class="clinic_users_title">
        Patients
	</div>
	<div class="options">
		<button  style="width: auto !important; margin-bottom: 15px;" class="btn_confirm">
			<i style ="font-size: 16px;" class='bx bx-plus-medical'></i>
			Add new patient
		</button>
	</div>
	<div class="table-outter">
		<div class="table">
			<div class="table-header fixer">
				<div id="dt" class="header__item"><p>First name</div>
				<div id="tf" class="header__item"><p>Last name</p></div>
				<div id="at" class="header__item"><p>Adress</p></div>
				<div class="row_options_empty"></div>
			</div>
			<div class="table-content">
                <div class="table-row">		
					<div class="table-data_nolh">
						<input class="input_fields" type="text"/>
					</div>
					<div class="table-data_nolh">
						<input class="input_fields" type="text"/>
					</div>
					<div class="table-data_nolh">
						
					</div>
                    <div class="row_options"></div>	
				</div>	
				<div class="table-row">		
					<div class="table-data name"><p>Drasko</p></div>
					<div class="table-data"><p>Miljkovic</p></div>
					<div class="table-data"><p>Ustanicka</p></div>
					<div class="row_options">
						<button class="btn_edit">
							<i style ="font-size: 22px;"  class='bx bxs-edit'></i>
						</button>
						<button class="btn_delete">
							<i style ="font-size: 22px;" class='bx bxs-trash-alt'></i>
						</button>
					</div>
				</div>                 
			</div>	
		</div>
	</div>
</div>
