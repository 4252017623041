import { Component, OnInit, ViewChild } from '@angular/core';
import { Clinic } from '../model/Clinic';
import { smsConfig } from '../model/SmsConfig'
import { Router } from '@angular/router';
import { WebApiService } from '../services/web-api.service';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, RichTextEditorComponent } from "@syncfusion/ej2-angular-richtexteditor";

@Component({
  selector: 'app-sms-configuration',
  templateUrl: './sms-configuration.component.html',
  styleUrls: ['./sms-configuration.component.scss'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
})
export class SmsConfigurationComponent implements OnInit {
  clinic: Clinic = new Clinic();
  clinicOriginal: Clinic = new Clinic();
  smsConfig: smsConfig = new smsConfig();
  smsConfigOriginal: smsConfig = new smsConfig();
  credit: number = 0;
  IsEditing: boolean = false;
  IsEditingAspSms: boolean = false;
  IsEditingTextAfterApp: boolean = false;
  IsEditingConfirmApp: boolean = false;
  IsEditingRejectApp: boolean = false;

  idClinic: number = Number(sessionStorage.getItem('idClinic'));
  clinicName: string = "demo";


  private editArea: HTMLElement;
  @ViewChild("RTE") public rteObj: RichTextEditorComponent;
  constructor(private router: Router, private webApiService: WebApiService) { }

  async ngOnInit() {
    await this.LoadClinic();
    await this.LoadSmsConfig();
    await this.LoadRemainingCredit();
  }

  async LoadSmsConfig() {
    this.smsConfigOriginal = await this.webApiService.GetSMSConfigByClinic();
    this.smsConfig = { ...this.smsConfigOriginal };
  }
  async LoadClinic() {
    this.clinicOriginal = await this.webApiService.GetClinicById();
    this.clinic = { ...this.clinicOriginal };
  }

  async LoadRemainingCredit() {
    if (this.smsConfigOriginal != null) {
      let ret = await this.webApiService.CheckCredits();
      let array = ret.split(' ');
      this.credit = Number(array[2]);
      this.credit = Number((Math.round(this.credit * 100) / 100).toFixed(2));
    }
  }

  ToggleAspSms() {
    this.IsEditingAspSms = !this.IsEditingAspSms;
  }

  ToggleTextAfterApp() {
    this.IsEditingTextAfterApp = !this.IsEditingAspSms;
  }

  ToggleTextConfirmApp() {
    this.IsEditingConfirmApp = !this.IsEditingConfirmApp;
  }

  ToggleTextRejectApp() {
    this.IsEditingRejectApp = !this.IsEditingRejectApp;
  }


  OpenFileUploader() {
    document.querySelector("input").click();
  }

  onCreate(): void {
    this.editArea = this.rteObj.inputElement as HTMLElement
    this.editArea.classList.add("e-droppable");
  }

  DiscardChangesAspSmsConfig() {
    this.smsConfig = { ...this.smsConfigOriginal }
    this.IsEditingAspSms = false;
  }

  async SaveChangesAspSmsConfig() {
    if (this.smsConfigOriginal == null)//ako je prvi unos
    {
      this.smsConfig.idClinic = this.idClinic;
      let ret = await this.webApiService.InsertSMSConfigByClinic(this.smsConfig);
    }
    else {
      let ret = await this.webApiService.UpdateSMSConfigByClinic(this.smsConfig);
      await this.webApiService.UpdateClinic(this.clinic);
    }
    window.location.reload();
  }

  DiscardChangesClinicAfterAp() {
    this.clinic = { ...this.clinicOriginal };
    this.IsEditingTextAfterApp = false;
  }

  async SaveChangesClinic() {
    let ret = await this.webApiService.UpdateClinic(this.clinic)
    window.location.reload();
  }


  DiscardChangesClinicConfirmText() {
    this.clinic = { ...this.clinicOriginal };
    this.IsEditingConfirmApp = false;
  }

  DiscardChangesClinicRejectText() {
    this.clinic = { ...this.clinicOriginal };
    this.IsEditingRejectApp = false;
  }




}
