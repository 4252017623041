<div class="body">
    <div class="title">
        Information
    </div>
    <div class="question">
        {{message}}
    </div>
    <div class="options">
        <button class="btn_edit"  mat-button (click)="Confirm(true)">Ok</button>
    </div>
</div>

