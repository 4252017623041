<div class="header">
    BEHANDLER
</div>
<div class="container">
    <div class="options">
		<button (click) = "AddNewPractitioner()"  style="width: auto !important; margin-bottom: 25px;" class="btn_confirm">
			<i style ="font-size: 16px;" class='bx bx-plus-medical'></i>
			Neuen Behandler hinzufügen
		</button>
	</div>
    <div class="pract-container">
        <div *ngFor = "let pract of practitioners" class="pract">
            <div style="margin-top: 15px;margin-bottom: -15px;" class="buttons-div">
                <button (click) = "MovePractLeft(pract)" class="btn_confirm arrows">
                    <i style ="font-size: 16px;" class='bx bxs-left-arrow'></i>
                </button>
                <div style="padding-top: 5px;">
                    {{pract.orderNo}}
                </div>
                <button (click) = "MovePractRight(pract)" class="btn_confirm arrows">
                    <i style ="font-size: 16px;" class='bx bxs-right-arrow'></i>
                </button>
            </div>
            <div class="pract-info" style="display: block; text-align: center;">
                <div class="pract-image" style="background-image: url('{{GetPath()}}/assets/images/{{pract.image}}');"></div>
                <div class="pract-header">
                    {{pract.name}}
                </div>
                <div class="pract-subheader">
                    {{pract.function}}
                </div>
                <div class="pract-subheader">
                    ID: {{pract.idPractitioner}}
                </div>
            </div>
            <div class="buttons-div">
                <button (click) = "OpenEditPractitioner(pract)" class="btn_edit">
                    Bearbeiten
                </button>
                <button (click) = "DeletePractitioner(pract)" class="btn_delete">
                    Löschen
                </button>
            </div>
        </div>
    </div>
</div>