<div class="agenda">
    <div class="header">
        AGENDA
    </div>
    <div class="container">
        <div class = "grids">
            <div class = "filterGrid">
                <select style="margin-top: 5px !important;" (input)="FilterPractChanged($event.target.value)" [(ngModel)]="idSelectedPractitioner" class="select_control">
                    <option [ngValue] = "pract.idPractitioner" *ngFor = "let pract of practitioners">{{pract.name}}</option>
                </select>
            </div>
            <div class = "calendarGrid">
                <full-calendar  #calendar id='calendar' [options]='calendarOptions' *ngIf='calendarVisible'></full-calendar>
            </div>
        </div>
    </div>
</div>


