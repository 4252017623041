import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indexToDay'
})
export class IndexToDayPipe implements PipeTransform {
  transform(index: number, ...args: unknown[]): unknown {
    return daysEn[index - 1];
  }
}

let daysEn: string[] = ['Monday', 'Tuesday', 'Wednesday','Thursday','Friday','Saturday','Sunday'];
