import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if(value == null) return ""
    let date: Date = new Date(value);
    if(date.getFullYear() < 1910) return "";
    return moment(value).format("DD.MM.yyyy");
  }

}

