
	<table *ngFor="let appType of pract.listAppTypes" [id]="NumberToString(appType.id)">
		<thead>
          <tr>
            <th style="text-align: center;" colspan="2">Terminart: {{appType.appTypeClinic.descriptionDe}}</th>
          </tr>
		  <tr>
			<th>Wochentag</th>
			<th>Wochentagstermine</th>
		  </tr>
		</thead>
		<tbody>
			<tr>
				<td day data-column="Weekday">Montag</td>
				<td class="flex-wrapper no-border" data-column="Termins">
					<div *ngFor = "let t of GetTerminsForDay(appType.id, 1)" class="termin">
						{{t.from | customDatePipe}} - {{t.to | customDatePipe}}
						<div class="unselect">
							X  &nbsp;&nbsp;
						</div>
					</div>
                    <button  class="btn_edit invisible">
						<i style ="font-size: 20px;"  class='bx bx-plus-medical'></i>
					</button>
				</td>
			</tr>
			<tr>
				<td day data-column="Weekday">Dienstag</td>
				<td class="flex-wrapper no-border" data-column="Termins">
					<div *ngFor = "let t of GetTerminsForDay(appType.id, 2)" class="termin">
						{{t.from | customDatePipe}} - {{t.to | customDatePipe}}
						<div class="unselect">
							X  &nbsp;&nbsp;
						</div>
					</div>
                    <button  class="btn_edit invisible">
						<i style ="font-size: 20px;"  class='bx bx-plus-medical'></i>
					</button>
				</td>
			</tr>
			<tr>
				<td day data-column="Weekday">Mittwoch</td>
				<td class="flex-wrapper no-border" data-column="Termins">
					<div *ngFor = "let t of GetTerminsForDay(appType.id, 3)" class="termin">
						{{t.from | customDatePipe}} - {{t.to | customDatePipe}}
						<div class="unselect">
							X  &nbsp;&nbsp;
						</div>
					</div>
                    <button  class="btn_edit invisible">
						<i style ="font-size: 20px;"  class='bx bx-plus-medical'></i>
					</button>
				</td>
			</tr>
			<tr>
				<td day data-column="Weekday">Donnerstag</td>
				<td class="flex-wrapper no-border" data-column="Termins">
					<div *ngFor = "let t of GetTerminsForDay(appType.id, 4)" class="termin">
						{{t.from | customDatePipe}} - {{t.to | customDatePipe}}
						<div class="unselect">
							X  &nbsp;&nbsp;
						</div>
					</div>
                    <button  class="btn_edit invisible">
						<i style ="font-size: 20px;"  class='bx bx-plus-medical'></i>
					</button>
				</td>
			</tr>
			<tr>
				<td day data-column="Weekday">Freitag</td>
				<td class="flex-wrapper no-border" data-column="Termins">
					<div *ngFor = "let t of GetTerminsForDay(appType.id, 5)" class="termin">
						{{t.from | customDatePipe}} - {{t.to | customDatePipe}}
						<div class="unselect">
							X  &nbsp;&nbsp;
						</div>
					</div>
                    <button  class="btn_edit invisible">
						<i style ="font-size: 20px;"  class='bx bx-plus-medical'></i>
					</button>
				</td>
			</tr>
			<tr>
				<td day data-column="Weekday">Samstag</td>
				<td class="flex-wrapper no-border" data-column="Termins">
					<div *ngFor = "let t of GetTerminsForDay(appType.id, 6)" class="termin">
						{{t.from | customDatePipe}} - {{t.to | customDatePipe}}
						<div class="unselect">
							X  &nbsp;&nbsp;
						</div>
					</div>
                    <button  class="btn_edit invisible">
						<i style ="font-size: 20px;"  class='bx bx-plus-medical'></i>
					</button>
				</td>
			</tr>
			<tr>
				<td day data-column="Weekday">Sonntag</td>
				<td class="flex-wrapper no-border" data-column="Termins">
					<div *ngFor = "let t of GetTerminsForDay(appType.id, 7)" class="termin">
						{{t.from | customDatePipe}} - {{t.to | customDatePipe}}
						<div class="unselect">
							X  &nbsp;&nbsp;
						</div>
					</div>
                    <button  class="btn_edit invisible">
						<i style ="font-size: 20px;"  class='bx bx-plus-medical'></i>
					</button>
				</td>
			</tr>
		</tbody>
	  </table>