<div class="header">
	BLOCKIERTE TERMINZEITEN
</div>
<div class="container">
	<div style="display: block; margin-bottom: -44px;">
		<div style="display: flex;">
			<button (click) = "AddNewBlockedAppointment()" class="btn_confirm add_blocked_btn">
				<i style ="font-size: 16px;" class='bx bx-plus-medical'></i>
				Neue hinzufügen
			</button>
			<button (click) = "DownloadPdf()" style="width: auto !important;height: 32px; margin-left: 10px;"class="btn_confirm">
				<i style ="font-size: 16px;" class='bx bxs-download'></i>
				PDF
			</button>
		</div>
	</div>
	<div class="appointments-actions">
		<div style="height: 45px; margin-left: auto; display: flex;">
			<select  (input)="SelectedPractChanged($event.target.value)"  [(ngModel)]="idSelectedPractitioner" class="select_control pract_select">
				<option [ngValue] = "0" value="" disabled selected>Behandler auswählen</option>
				<option [ngValue] = "-1" value="" >Alle</option>
				<option [ngValue] = "pract.idPractitioner" *ngFor = "let pract of practitioners">{{pract.name}}</option>
			</select>
		</div>	
	</div>
	<div  class="table-outter">
		<table  id="pdfTable">
			<thead>
			  <tr>
				<th noWrap>Praktikerin</th>
				<th noWrap>Datum - Zeit von</th>
				<th noWrap>Datum - Zeit zum</th>
				<th noWrap>Text</th>
				<th></th>
			  </tr>
			</thead>
			<tbody>
			  <tr *ngFor = "let app of blockedAppsFiltered">
				<td noWrap data-column="Practitioner">{{app.practitioner.name}}</td>
				<td noWrap data-column="Date - Time from">{{app.from | dateFormat}} - {{app.from | dateToTime}}</td>
				<td noWrap data-column="Date - Time to">{{app.to | dateFormat}} - {{app.to | dateToTime}}</td>
				<td extend style="text-align: left;word-break: break-word;" data-column="Text">{{app.text}}</td>
				<td class="actions">
				  <button (click) = "EditSelectedBlockedAppointment(app)" class="btn_edit">
					  <i style ="font-size: 22px;"  class='bx bxs-edit'></i>
				  </button>
				  <button (click) = "Delete(app)" class="btn_delete">
					  <i style ="font-size: 22px;" class='bx bxs-trash-alt'></i>
				  </button>
				</td>
			  </tr>
			</tbody>
		  </table>
	</div>	
	<div *ngIf = "blockedAppsFiltered.length < 1" class="no_appointments_note">Keine blockierten Termine für den ausgewählten Monat.</div>
</div>

