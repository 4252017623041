<div class="header">
	SMS-PROTOKOLLE DER PRAXIS
</div>
<div class="container">
	<div class="table-outter">
		<table>
			<thead>
			  <tr>
				<th noWrap>Datum</th>
				<th noWrap>Zeit</th>
				<th noWrap>Mobiltelefon</th>
				<th noWrap>Vorname</th>
				<th noWrap>Nachname</th>
				<th noWrap>Text</th>
			  </tr>
			</thead>
			<tbody>
			  <tr *ngFor = "let log of smsLogs">
				<td noWrap data-column="Date">{{log.date | dateFormat}}</td>
				<td noWrap data-column="Time">{{log.date | dateToTime}}</td>
				<td noWrap data-column="Mobile">{{log.mobile}}</td>
				<td noWrap data-column="First name">{{log.patient?.firstName}}</td>
				<td noWrap data-column="Last name">{{log.patient?.lastName}}</td>
				<td extend class="noWrap" title="{{log.text}}" data-column="Text">{{log.text}}</td>
			  </tr>
			</tbody>
		  </table>
	</div>
</div>

