import { practitioner } from "./Practitioner"

export class block_appointment
{
    public id: string;
    public idPractitioner: number;
    public practitioner: practitioner;
    public from: string;
    public to: string;
    public text: string;


    public title:string;
    public start:Date;
    public end:Date;
    public color:string;
    public resourceId:string;
    public display: string;

}