import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { practitioner } from '../model/Practitioner';
import { WebApiService } from '../services/web-api.service';
import * as moment from 'moment';
import { block_appointment } from '../model/BlockAppointment';


@Component({
  selector: 'app-add-blocked-appointment',
  templateUrl: './add-blocked-appointment.component.html',
  styleUrls: ['./add-blocked-appointment.component.scss']
})
export class AddBlockedAppointmentComponent implements OnInit {
  errorMessage: string = "";
  practitioners: practitioner[];
  idClinic: number = Number(sessionStorage.getItem('idClinic'));
  selectedPractitionerId: number = 0;
  selectedDate: Date;
  selectedDateFrom: string;
  selectedDateTo: string;
  selectedTimeTo: string;
  selectedTimeFrom: string;
  text:string;
  appToEdit: block_appointment;
  isError: boolean = false;
  constructor(public matDialog:MatDialog, public webApiService: WebApiService, public dialogRef: MatDialogRef<AddBlockedAppointmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    {
      dialogRef.disableClose = true;
      if(data.app != null) //ako editujemo
      {
        this.appToEdit = data.app;
        this.SetEditValues();
      }
      else // ako je novi
      {
        this.SetDates();
        this.selectedPractitionerId = data.idPract;
        if(this.selectedPractitionerId == -1)
        {
          this.selectedPractitionerId = 0;
        }
      }
    } 
  SetEditValues() 
  {
    this.text = this.appToEdit.text;
    this.selectedPractitionerId = this.appToEdit.idPractitioner;
    this.selectedDateFrom = this.appToEdit.from.split('T')[0];
    this.selectedTimeFrom = this.appToEdit.from.split('T')[1];
    this.selectedTimeFrom = this.selectedTimeFrom.slice(0,5);
    this.selectedDateTo = this.appToEdit.to.split('T')[0];
    this.selectedTimeTo = this.appToEdit.to.split('T')[1];
    this.selectedTimeTo = this.selectedTimeTo.slice(0,5);
  }

  ngOnInit(): void {
    this.LoadPractitioners();
  }

  async LoadPractitioners()
  {
    this.practitioners = await this.webApiService.GetAllPractitioners(this.idClinic);
  }

  CloseModal()
  {
    this.dialogRef.close();
  }

  DateChanged(value)
  {

  }

  SetDates()
  {
    if(sessionStorage.getItem('dateFrom') && sessionStorage.getItem('dateTo')) 
    {
      this.selectedDateFrom = sessionStorage.getItem('dateFrom');
      this.selectedDateTo = sessionStorage.getItem('dateTo');
    }
    else
    {
      this.selectedDateFrom = moment(new Date()).format("yyyy-MM-DD");
      this.selectedDateTo = moment(new Date()).format("yyyy-MM-DD");
    }
    this.selectedTimeFrom = "08:00";
    this.selectedTimeTo = "17:00"
  }

  async InsertBlockedAppointment()
  {
    this.isError = false;
    if(this.Validate())
    {
      let app: block_appointment = new block_appointment();
      app.from = this.selectedDateFrom + "T" + this.selectedTimeFrom;
      app.to = this.selectedDateTo + "T" + this.selectedTimeTo;
      app.idPractitioner = this.selectedPractitionerId;
      app.text = this.text;
      let ret = null;
      if((new Date(app.from) >= new Date(app.to)) || this.selectedPractitionerId == 0)
      {
        this.errorMessage = "Time to must be greater that time from!"
        this.isError = true;
        return;
      }
      if(this.appToEdit == null)
      {
         ret = await this.webApiService.InsertBlockedAppointment(app);
      }
      else
      {
        app.id = this.appToEdit.id;
        ret = await this.webApiService.UpdateBlockedAppointment(app);
      }
      sessionStorage.setItem('dateFrom',this.selectedDateFrom);
      sessionStorage.setItem('dateTo',this.selectedDateTo);
      sessionStorage.setItem('idSelectedPractitioner',this.selectedPractitionerId.toString());
      this.dialogRef.close(true);
    }
  }

  DatePlusTime(date:string, time: string)
  {
    
  }

  async SelectedDateFromChanged(value)
  {
    this.selectedDateFrom = value;
    this.selectedDateTo = value;
  }

  async SelectedDateToChanged(value)
  {
    this.selectedDateTo = value;
  }

  Validate()
  {
    this.isError = false;
    if(this.selectedTimeFrom != "" && this.selectedTimeTo != "" && this.selectedPractitionerId != 0)
    {
      return true
    }
    else
    {
      this.isError = true;
      this.errorMessage = "Enter all fields with *";
      return false;
    }
  }

}
