<body>
    <div class="nav-class">
      <nav>
          <ul top class="menu">
              <li class="left">
                <a class="buttonOpen">
                    <i (click) = "ShowHideMenu()" class='bx bx-menu' ></i>
                </a>
              </li>

              <li class="center clinic_name">{{clinic.name}}</li>

              <li class="right">
                <a class="button" (click)="LogoutUser()" href="javascript:void(0)">Abmelden</a>
              </li>
          </ul>
        </nav>
    </div>
  
    <div  class="sidebar close">
      <ul class="nav-links">
        <li id = "apps">
          <div (click) = "OpenSubmenu($event)" class="iocn-link">
            <a class="responsive-img" href="javascript:void(0)">
              <i class='bx bx-calendar' ></i>
              <span class="link_name">Terminverwaltung</span>
            </a>
            <i class='bx bxs-chevron-down arrow' ></i>
          </div>
          <ul class="sub-menu">
            <li><a class="link_name no-click" href="javascript:void(0)">Terminverwaltung</a></li>
            <li><a href="javascript:void(0)" (click) = "SwitchToAgendaView()">Agenda</a></li>
            <li><a href="javascript:void(0)" (click) = "SwitchToAllAppointmentsView()" >Termine online</a></li>
            <li><a href="javascript:void(0)" class="alert" (click) = "SwitchToNewAppointmentsView()" >Neue Termine</a></li>
            <li *ngIf = "!clinic.clientHasEDP"><a href="javascript:void(0)" (click) = "SwitchToManualAppointmentsView()" >Termine manuell</a></li>
            <li><a href="javascript:void(0)" (click) = "SwitchToBlockAppointmentsView()" >Termine blockiert</a></li>
            <li><a href="javascript:void(0)" (click) = "SwitchToSmsLogView()" >SMS Protokoll</a></li>
          </ul>
        </li>
        <li *ngIf = "userRole == 'ClinicAdmin'" id = "clinic">
          <div (click) = "OpenSubmenu($event)" class="iocn-link">
            <a class="responsive-img" href="javascript:void(0)">
              <i class='bx bx-clinic' ></i>
              <span class="link_name">Systemverwaltung</span>
            </a>
            <i class='bx bxs-chevron-down arrow' ></i>
          </div>
          <ul class="sub-menu">
            <li *ngIf = "userRole == 'ClinicAdmin'"><a class="link_name no-click" href="javascript:void(0)">Systemverwaltung</a></li> <!--Ovje ide *ngIf = "userRole == 'ClinicAdmin'"-->
            <li><a href="javascript:void(0)" (click) = "SwitchToClinicInfoView()">Praxisdaten</a></li>
            <li><a href="javascript:void(0)" (click) = "OpenColorEditor()">Designfarben</a></li>
            <li><a href="javascript:void(0)" (click) = "OpenSmsConfiguration()">SMS Konfiguration</a></li>
            <li><a href="javascript:void(0)" (click) = "SwitchToPractitionersView()">Behandler</a></li>
            <li><a href="javascript:void(0)" (click) = "SwitchToAppointmentTypesView()">Terminarten</a></li>
            <li><a href="javascript:void(0)" (click) = "SwitchToWorkingTimes()">Arbeitsintervalle</a></li>
            <li><a href="javascript:void(0)" (click) = "SwitchToClinicUsersView()">Benutzer</a></li>
            <li><a href="javascript:void(0)" (click) = "SwitchToHolidaysView()">Feiertage</a></li>
            <!--<li><a href="javascript:void(0)" (click) = "SwitchToPatientsView()">Patients</a></li>
            <li><a href="javascript:void(0)">Days off</a></li>-->
          </ul>
        </li>
      </ul>
    </div>
    <section class="home-section">
      <router-outlet></router-outlet>
    </section>
  </body>
  
  
