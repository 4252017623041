import { Component, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { AddClinicUserComponent } from '../add-clinic-user/add-clinic-user.component'
import { WebApiService } from '../services/web-api.service';
import { clinic_user } from '../model/Clinic_User';
import { QuestionModalComponent } from '../question-modal/question-modal.component';
@Component({
  selector: 'app-clinic-users',
  templateUrl: './clinic-users.component.html',
  styleUrls: ['./clinic-users.component.scss']
})
export class ClinicUsersComponent implements OnInit {
  idClinic: number = Number(sessionStorage.getItem('idClinic'));
  clinicUsers: clinic_user[] = [];
  clinicUserToEdit: clinic_user = null;
  constructor(private matDialog : MatDialog, private webApiService: WebApiService ) { }

  async ngOnInit()
  {
    await this.LoadClinicUsers();
  }

  handleRadioCheckedChange(event)
  {
    document.documentElement.style.setProperty('--option-opacity','1.0');
    document.documentElement.style.setProperty('--option-pointer-event','all');
    document.documentElement.style.setProperty('--option-cursor','pointer');
    
  }

  OpenAddClinicUserModal()
  {
    let dialogRef = this.matDialog.open(AddClinicUserComponent,{
      panelClass: 'dialog-class',
      data: {
        userToEdit:this.clinicUserToEdit
      },
      height: "auto",
      width: "600px"
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result == true) 
      {
        window.location.reload();
      }
    })

  }

  async LoadClinicUsers()
  {
    this.clinicUsers = await this.webApiService.GetClinicUsers(this.idClinic);
    this.clinicUsers = this.clinicUsers.filter(u => u.roleName != "Synchronization");
  }

  OpenEditClinicUser(user:clinic_user)
  {
    this.clinicUserToEdit = user;
    this.OpenAddClinicUserModal();
  }

  AddNewClinicUser()
  {
    this.clinicUserToEdit = null;
    this.OpenAddClinicUserModal();
  }

  async Delete(user:clinic_user)
  {
    let dialogRef = this.matDialog.open(QuestionModalComponent,{
      panelClass: 'dialog-class',
      data: {
        question:"Sind Sie sicher, dass Sie die ausgewählte Benutzer löschen möchten?"
      },
      height: "auto",
    });    
    dialogRef.afterClosed().subscribe(async result => {
      if (result == true) 
      {
        let ret = await this.webApiService.DeleteClinicUser(user);
        window.location.reload();
      }
    })

  }
}


