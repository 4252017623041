import { Component, AfterViewInit,AfterContentChecked, OnInit  } from '@angular/core';
import { practitioner } from '../model/Practitioner';
import { Router, ActivatedRoute } from '@angular/router';
import { WebApiService } from '../services/web-api.service';
import { WeekdayTermin } from '../model/WeekdayTermin';
import { app_type_for_practitioner } from '../model/Appointment_type_practitioner';
import { Observable } from 'rxjs';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
@Component({
  selector: 'app-working-time-pdf',
  templateUrl: './working-time-pdf.component.html',
  styleUrls: ['./working-time-pdf.component.scss']
})
export class WorkingTimePDFComponent implements AfterViewInit {
  pract: practitioner = null;
  isLoaded: boolean = false;
  //listAppTypes : app_type_for_practitioner[] = []
  constructor(private router: Router,
              private route: ActivatedRoute,
              private webApiService: WebApiService) 
  {
    this.pract =JSON.parse(this.route.snapshot.paramMap.get('pract'));
  }

  async ngAfterViewInit(){
    let cnt = 1;
    const doc = new jsPDF();
    doc.text(this.pract.name, 14, 25);
    this.pract.listAppTypes.forEach(appType => {
      autoTable(doc, {
        columnStyles:
        {
          0: { minCellWidth: 35, cellWidth:35},
          1: { minCellWidth: 40},
        },
        margin:{top:30},
        pageBreak: "avoid",
        html: '#' + this.NumberToString(appType.id)});
  
    });
    doc.save('WorkingIntervals.pdf');
    this.router.navigateByUrl('home/work-times');
  }

  GetTerminsForDay(idAppType: number,weekdayId: number)
  {
    let temp: app_type_for_practitioner = this.pract.listAppTypes.filter(wt => wt.id == idAppType)[0];
    let temp1: WeekdayTermin[] = temp.weekdayTermins;
    return temp1.filter(wt => wt.idWeekDay == weekdayId)[0]?.termins;
  }

   NumberToString(num) {
    const alphabet = "JABCDEFGHI";
    const digits = Math.floor(num).toString().split("").map(Number);
    return digits.reduce((str, digit) => {
        return str + alphabet[digit];
    }, "");
}



}
