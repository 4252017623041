<div class="body">
    <div class="title">
        Bestätigung Abbruch
    </div>
    <div class="question">
        Geben Sie den Grund ein, warum Sie den Termin absagen:
    </div>
    <div>
        <textarea style="font-size: 16px;" type="text" id="datepicker" cdkTextareaAutosize  [(ngModel)] = "text" class="input_field text_area"></textarea>
    </div>
    <div class="options">
        <button class="btn_edit"  mat-button (click)="Confirm(true)">Bestätigen</button>
        <button class="btn_delete"  mat-button (click)="Confirm(false)">Abbruch</button>
    </div>
</div>