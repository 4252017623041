<div class="header">
	BENUTZER
</div>
<div class="container">
	<div class="options">
		<button (click) = "AddNewClinicUser()" style="width: auto !important; margin-bottom: 15px;" class="btn_confirm">
			<i style ="font-size: 16px;" class='bx bx-plus-medical'></i>
			Neuen Benutzer hinzufügen
		</button>
	</div>
<table>
  <thead>
    <tr>
      <th>Benutzername</th>
      <th>Email</th>
      <th>Rolle</th>
      <th>Erste Anmeldung</th>
      <th>Letzte Anmeldung</th>
	  <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor = "let user of clinicUsers">
      <td data-column="Username">{{user.username}}</td>
      <td data-column="Email">{{user.email}}</td>
      <td data-column="Rolle">{{user.roleName}}</td>
      <td data-column="First Login">{{user.firstLogin | dateFormat}}</td>
      <td data-column="Last Login">{{user.lastLogin | dateFormat}}</td>
	  <td class="actions">
		<button (click) = "OpenEditClinicUser(user)" class="btn_edit">
			<i style ="font-size: 22px;"  class='bx bxs-edit'></i>
		</button>
		<button (click) = "Delete(user)" class="btn_delete">
			<i style ="font-size: 22px;" class='bx bxs-trash-alt'></i>
		</button>
	  </td>
    </tr>
  </tbody>
</table>
</div>
