import { Component, OnInit } from '@angular/core';
import { clinic_holiday } from '../model/clinic_holiday';
import { WebApiService } from '../services/web-api.service';
import { Canton } from '../model/Canton';
import { Clinic } from '../model/Clinic';
import { QuestionModalComponent } from '../question-modal/question-modal.component';
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-clinic-holidays',
  templateUrl: './clinic-holidays.component.html',
  styleUrls: ['./clinic-holidays.component.scss']
})
export class ClinicHolidaysComponent implements OnInit {
  idClinic: number = Number(sessionStorage.getItem('idClinic'));
  clinicHolidays: clinic_holiday[] = []
  cantons: Canton[] = [];
  selectedCanton: Canton;
  clinic: Clinic;
  constructor(private webApiService: WebApiService, private matDialog: MatDialog) { }


  async ngOnInit() {
    await this.LoadClinic();
    await this.LoadClinicHolidays();
    await this.LoadCantons();
  }

  async LoadClinicHolidays() {
    this.clinicHolidays = await this.webApiService.GetHolidaysByClinic(this.idClinic);
  }

  async SaveHolidaysChanges(holiday: clinic_holiday) {
    await this.webApiService.UpdateHoliday(holiday);
    window.location.reload();
  }

  async CheckboxChanged(event, holiday: clinic_holiday) {
    holiday.isCelebrated = event.target.checked;
    await this.SaveHolidaysChanges(holiday);
  }

  async LoadCantons() {
    this.cantons = await this.webApiService.GetAllCantons();
    this.selectedCanton = this.cantons.filter(c => c.idCanton == this.clinic.idCanton)[0];
  }

  async LoadClinic() {
    this.clinic = await this.webApiService.GetClinicById();
  }

  async UpdateClinicCanton() {
    this.clinic.idCanton = this.selectedCanton.idCanton;
    await this.webApiService.UpdateClinicCanton(this.clinic);

  }

  ChangeCanton() {
    let dialogRef = this.matDialog.open(QuestionModalComponent, {
      panelClass: 'dialog-class',
      data: {
        question: "Sind Sie sicher, dass Sie die Klinikregion wechseln möchten?"
      },
      height: "auto",
    });
    dialogRef.afterClosed().subscribe(async result => {
      await this.UpdateClinicCanton();
      window.location.reload();
    })
  }




}
