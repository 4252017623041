<div>
    <div *ngIf = "!isEditing" class="header">
        Klinikbenutzer hinzufügen
    </div>
    <div *ngIf = "isEditing" class="header">
        Klinikbenutzer bearbeiten
    </div>
    <div class="form">
        <div class="field-label">
            Nutzername*
        </div>
        <input [(ngModel)] = "user.username" type="text" class="input_field"/>

        <div  class="field-label">
            Passwort*
        </div>
        <input  [(ngModel)] = "user.password" type="password" class="input_field"/>

        <div  class="field-label">
            Bestätige das Passwort*
        </div>
        <input  [(ngModel)] = "confirmPassword" type="password" class="input_field"/>

        <div class="field-label">
            Email*
        </div>
        <input [(ngModel)] = "user.email" type="text" class="input_field"/>

        <div class="field-label">
            Rolle*
        </div>
        <select [(ngModel)]="selectedRole" class="select_control">
            <option [ngValue] = "null" disabled selected>Rolle auswählen</option>
            <option [ngValue] = "role" *ngFor = "let role of clinicRoles">{{role.name}}</option>
        </select>

        <!--<div class="field-label">
            Birthday
        </div>
        <app-date-picker (dateChange)="onSelectedDateChanged($event)" [selectedDate]="selectedDate"></app-date-picker>-->
        <div style="height: 35px;">
            <div *ngIf = "isError" class="error">
                {{errorMessage}}
            </div>
        </div>
    </div>
    <div class="close_modal">
        <button (click) = "CloseModal()" class="btn_close">
            Abbruch
        </button>
        <button (click) = "InsertClinicUser()" class="btn_confirm">
            Einreichen
        </button>
    </div>
</div>
