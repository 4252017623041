import { app_type_for_practitioner } from './Appointment_type_practitioner'

export class practitioner{
    public idPractitioner : number;
    public name: string;
    public image: string;
    public function: string;
    public description: string;
    public colorBackground: string;
    public idClinic: string;
    public listAppTypes: app_type_for_practitioner[];
    public orderNo: number;
    public isActive: boolean;

    public dateFrom: Date;
    public dateTo: Date;
    
    
}