import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router'
import { MatDialog } from "@angular/material/dialog";
import { EditColoursComponent } from '../edit-colours/edit-colours.component';
import { Clinic } from '../model/Clinic';
import { Company } from '../model/Company'
import { WebApiService } from '../services/web-api.service';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, RichTextEditorComponent } from "@syncfusion/ej2-angular-richtexteditor";
import * as XLSX from 'xlsx';
import { patient } from '../model/Patient';
import { ExcelFile } from '../model/ExcelFile';
import { InfoModalComponent } from '../info-modal/info-modal.component';

@Component({
  selector: 'app-clinic-info',
  templateUrl: './clinic-info.component.html',
  styleUrls: ['./clinic-info.component.scss']
})
export class ClinicInfoComponent implements OnInit {
  @ViewChild("inputValue", { static: false }) inputValue: ElementRef;
  private editArea: HTMLElement;

  IsEditingAppManagement : boolean = false;
  IsEditingHomeDef : boolean = false;
  IsEditingCompany: boolean = false;

  imageUrl: string = "";
  fileToUpload: File | null = null;
  patientsFile: File | null = null;
  patientData: patient[] = [];
  formData: FormData = new FormData();
  clinicName:string = "demo";
  clinicOriginal: Clinic;
  clinic: Clinic = new Clinic();
  company: Company = new Company();
  companyOriginal: Company = new Company();
  idClinic: number = Number(sessionStorage.getItem('idClinic'));
  @ViewChild("RTE") public rteObj: RichTextEditorComponent;
  constructor(private router: Router, private matDialog: MatDialog, private webApiService: WebApiService) { }

  async ngOnInit() {
    await this.LoadClinicInfo();
    await this.LoadCompany();
  }

  ToggleAppManagementEditingData()
  {
    this.IsEditingAppManagement = !this.IsEditingAppManagement;
  }

  ToggleHomepageEditingData()
  {
    this.IsEditingHomeDef = !this.IsEditingHomeDef;
  }

  ToggleCompanyEditingData()
  {
    this.IsEditingCompany = !this.IsEditingCompany;
  }

  OpenFileUploader()
  {
    document.getElementById("input_clinic").click();
  }

  OpenFileUploaderCompany()
  {
    document.getElementById("input_company").click();
  }

  EditColors()
  {
    let dialogRef = this.matDialog.open(EditColoursComponent,{
      panelClass: 'dialog-class',
      data: {
        colour: this.clinic.color,
      },
      height: "auto",
      width: "600px"
    });
  }

  async LoadClinicInfo()
  {
    this.clinicOriginal = await this.webApiService.GetClinicById();
    this.clinic = {...this.clinicOriginal}; // kopiram
  }

  async LoadCompany()
  {
    this.companyOriginal = await this.webApiService.GetCompanyById(this.clinic.idCompany);
    this.company = {...this.companyOriginal};
  }

  async SaveChanges()
  {

    let ret = await this.webApiService.UpdateClinic(this.clinic);
    window.location.reload();
  }

  DiscardChangesAppManagement()
  {
    this.clinic = {...this.clinicOriginal};
    this.IsEditingAppManagement = false;
  }

  DiscardChangesHomepageDef()
  {
    this.clinic = {...this.clinicOriginal};
    this.IsEditingHomeDef = false;
  }

  GetPath(){
    return this.webApiService.GetAgendaClientPath();
  }

  async handleFileInput(files: FileList) 
  {
    this.fileToUpload = files.item(0);
    this.PrepareUploadImage();
  }

  
  async handleFileInputCompany(files: FileList) 
  {
    this.fileToUpload = files.item(0);
    this.PrepareUploadImageCompany();
  }

  async PrepareUploadImage()
  {
    this.formData = new FormData();
    this.formData.append('image', this.fileToUpload, "logo.jpg");
    await this.webApiService.UploadLogoImage(this.formData);
    window.location.reload();
  }

  async PrepareUploadImageCompany()
  {
    this.formData = new FormData();
    this.formData.append('image', this.fileToUpload, "logo.jpg");
    await this.webApiService.UploadCompanyLogo(this.formData);
    window.location.reload();
  }

  onCreate(): void {
    this.editArea = this.rteObj.inputElement as HTMLElement
    this.editArea.classList.add("e-droppable");
  }

  async SaveChangesCompany()
  {
    let ret = await this.webApiService.UpdateCompany(this.company);
    window.location.reload();
  }
  
  DiscardChangesCompany()
  {
    this.company = {...this.companyOriginal};
    this.IsEditingCompany = false;
  }

  OpenFile()
  {
    document.getElementById("patientInput").click();
  }

  async HandleInput(files: FileList)
  {
    this.patientsFile = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.patientsFile);
    reader.onload = async () => {
        let excl: ExcelFile = new ExcelFile();
        excl.clinicName = this.clinic.urlName;
        excl.fileBytes = reader.result;
        excl.fileBytes = excl.fileBytes.slice(78);
        let ret: any = await this.webApiService.UploadPatients(excl);
        this.ShowInfo(ret);

    };
  }

  ShowInfo(message: string)
  {
    let dialogRef = this.matDialog.open(InfoModalComponent,{
      panelClass: 'dialog-class',
      data: {
        question: message
      },
      height: "auto",
    });    
  }


}
