import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(request.url.includes("login")) //ako je ova metoda za dobijanje tokena, da pusti zahtjev bez da u njega stavlja token
        {
          return next.handle(request);
        }
        const token = sessionStorage.getItem('token');
        if(token != "")
        {
          const TOKEN_HEADER_KEY = 'Authorization';
          let authReq = request;
          authReq = request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
          return next.handle(authReq);
        }
        else
        {
          return next.handle(request);
        }
    }
}
