import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  CalendarApi,
  CalendarOptions,
  DatesSetArg,
  FullCalendarComponent,
} from '@fullcalendar/angular';
//import  resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import deLocale from '@fullcalendar/core/locales/de';
import * as moment from 'moment';
import { Appointment } from '../model/Appointment';
import { AppointmentStatus } from '../model/AppointmentStatus';
import { block_appointment } from '../model/BlockAppointment';
import { Clinic } from '../model/Clinic';
import { practitioner } from '../model/Practitioner';
import { SharedService } from '../services/shared.service';
import { WebApiService } from '../services/web-api.service';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss'],
})
export class AgendaComponent implements OnInit, AfterViewInit {
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  calendarVisible = true;
  calendarOptions: CalendarOptions = null;
  calendarApi: CalendarApi = null;
  appointments: Appointment[] = [];
  filteredAppointments: Appointment[] = [];
  blockedAppointments: block_appointment[] = [];
  filteredBlockedAppointments: block_appointment[] = [];
  startDate: Date;
  endDate: Date;
  statuses: AppointmentStatus[] = [];
  practitioners: practitioner[] = [];
  idSelectedPractitioner: number = 0;
  clinicName: string = 'demo';
  clinics: Clinic[] = [];
  clinic: Clinic = new Clinic();

  lang: string = 'en';
  idClinic: number = Number(sessionStorage.getItem('idClinic'));

  constructor(
    private sharedService: SharedService,
    private webApiService: WebApiService,
    private changeRef: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {}

  async ngOnInit() {
    await this.LoadClinic();
    this.SubscribeToServer();
    this.LoadPractitioners();
    this.GetAppointmentStatus();
  }

  ngAfterViewInit(): void {
    this.LoadCalendarOptions();
    this.changeRef.detectChanges();
  }

  SubscribeToServer() {
    this.sharedService.IsResized.subscribe((msg) => {
      if (msg == true) {
        setTimeout(() => {
          this.WindowSizeChanged();
        }, 500);
      }
    });
  }
  async LoadClinic() {
    this.clinic = await this.webApiService.GetClinicById();
  }
  LoadCalendarOptions() {
    this.calendarOptions = {
      headerToolbar: {
        right: 'prev,today,next,timeGridWeek,dayGridMonth,listWeek',
        center: 'title',
        left: '',
      },
      locales: [deLocale],
      //schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
      //resources: this.resources,
      initialView: 'dayGridMonth',
      showNonCurrentDates: false,
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        omitZeroMinute: false,
        meridiem: 'narrow',
      },
      displayEventEnd: true,
      fixedWeekCount: false,
      weekends: true,
      allDaySlot: false,
      slotMinTime: '06:00:00',
      scrollTime: '08:00:00',
      slotDuration: '00:15:00',
      firstDay: 1,
      editable: false,
      selectable: false,
      nowIndicator: true,
      selectMirror: true,
      windowResizeDelay: 1,
      slotLabelFormat: {
        hour: '2-digit',
        minute: '2-digit',
        omitZeroMinute: false,
        meridiem: 'short',
      },
      themeSystem: 'standard',
      dayHeaderFormat: { weekday: 'short' },
      dayMaxEvents: false,
      slotEventOverlap: false,
      eventLongPressDelay: 500, //800
      //dateClick: this.handleDateSelect.bind(this),
      //eventClick: this.f.bind(this),
      //eventsSet: this.handleEvents.bind(this),
      datesSet: this.SelectedDateChanged.bind(this),
      dayHeaderWillUnmount: this.ViewTypeChanged.bind(this),
      //dayCellWillUnmount: this.ViewTypeChanged.bind(this),
      //eventDrop: this.UpdateAppointment.bind(this),
      //eventResize: this.UpdateAppointment.bind(this),
      eventContent: this.EventContentChange,
    };
  }

  EventContentChange(eventInfo, createElement) {
    var innerHtml;
    var timeHtml;
    var colorTimeHtml;
    if (eventInfo.event._def.extendedProps.description) {
      timeHtml =
        moment(eventInfo.event._def.extendedProps.timeFrom).format('HH:mm') +
        ' - ' +
        moment(eventInfo.event._def.extendedProps.timeTo).format('HH:mm') +
        '<br>';
      colorTimeHtml =
        '<div style = "display:flex; color: black; font-weight: 700; ">' +
        '<div style = "height:8px; width:8px; border-radius: 50%; margin-top:3px; margin-right:3px; background:' +
        eventInfo.event._def.ui.backgroundColor +
        '"></div>' +
        timeHtml +
        '</div>';
      innerHtml =
        colorTimeHtml +
        eventInfo.event._def.title +
        '<br>' +
        eventInfo.event._def.extendedProps?.description;
      return (createElement = {
        html:
          '<div style = " font-weight: 550; color: black; white-space:initial;">' +
          innerHtml +
          '</div>',
      });
    } else {
      if (
        !moment(eventInfo.event._def.extendedProps.to).isAfter(
          eventInfo.event._def.extendedProps.from,
          'day'
        )
      ) {
        //pitamo da li je multiday block
        timeHtml =
          moment(eventInfo.event._def.extendedProps.from).format('HH:mm') +
          ' - ' +
          moment(eventInfo.event._def.extendedProps.to).format('HH:mm') +
          '<br>';
        colorTimeHtml =
          '<div style = "display:flex; font-weight: 700; ">' +
          '<div style = "height:8px; width:8px; border-radius: 50%; margin-top:3px; margin-right:3px; background: ' +
          eventInfo.event._def.ui.backgroundColor +
          '"></div>' +
          timeHtml +
          '</div>';
        innerHtml =
          colorTimeHtml +
          eventInfo.event._def.title +
          '<br>' +
          eventInfo.event._def.extendedProps?.text;
        return (createElement = {
          html:
            '<div style = " font-weight: 550; white-space:initial;">' +
            innerHtml +
            '</div>',
        });
      }
    }
  }

  IsMultiDay(start, end) {
    if (moment(end).date > moment(start).date) {
      return true;
    }
    return false;
  }

  ViewTypeChanged() {
    const calendarApi = this.calendarComponent.getApi();
    if (calendarApi.view.type == 'dayGridMonth') {
      this.calendarOptions.dayHeaderFormat = { weekday: 'short' };
    } else if (calendarApi.view.type == 'timeGridWeek') {
      this.calendarOptions.dayHeaderFormat = {
        weekday: 'short',
        month: 'numeric',
        day: 'numeric',
        omitCommas: false,
      };
    } else if (calendarApi.view.type == 'listWeek') {
      this.calendarOptions.dayHeaderFormat = {
        weekday: 'short',
        month: 'numeric',
        day: 'numeric',
        omitCommas: false,
      };
    }
  }

  WindowSizeChanged() {
    const calendarApi = this.calendarComponent.getApi();
    calendarApi.updateSize();
  }

  async LoadAppointments(startDate: Date, endDate: Date) {
    this.appointments = [];
    this.filteredAppointments = [];
    this.blockedAppointments = [];
    this.filteredBlockedAppointments = [];
    let apps = await this.webApiService.GetAllAppointments(
      this.idClinic,
      startDate.toISOString(),
      endDate.toISOString()
    );
    let blockedApps = await this.webApiService.GetBlockedAppointmentsByClinic(
      this.idClinic,
      startDate.toISOString(),
      endDate.toISOString()
    );
    this.GenerateAgendaData(apps);
    this.GenerateBlockedAppData(blockedApps);
    this.SetAppointmentsInCalendar();
  }

  SetAppointmentsInCalendar() {
    const calendarApi = this.calendarComponent.getApi();
    calendarApi.removeAllEventSources();
    calendarApi.addEventSource(this.filteredAppointments);
    calendarApi.addEventSource(this.filteredBlockedAppointments);
  }

  GenerateAgendaData(apps: Appointment[]) {
    apps.forEach((element) => {
      if (element.appTypeColor && element.appTypeName) {
        element.color = element.appTypeColor.slice(0, 7);
      } else {
        element.color = element.appointmentType.appTypeClinic.color;
        element.appTypeColor.slice(0, 7);
      }
      element.title = element.appTypeName;
      element.description =
        element.patient?.firstName + ' ' + element.patient?.lastName;
      element.start = new Date(element.timeFrom.toString());
      element.start.setSeconds(0);
      element.end = new Date(element.timeTo.toString());
      element.end.setSeconds(0);
      this.appointments.push(element);
      if (this.CheckFilters(element)) {
        this.filteredAppointments.push(element);
      }
    });
  }

  GenerateBlockedAppData(apps: block_appointment[]) {
    apps.forEach((element) => {
      element.title =
        'Blocked until ' + moment(element.to).format('DD.M.YYYY HH:mm');
      element.color = '#871217'; // Treba da se povuce ovo iz baze
      element.start = new Date(element.from.toString());
      element.start.setSeconds(0);
      element.end = new Date(element.to.toString());
      element.end.setSeconds(0);
      this.blockedAppointments.push(element);
      if (this.CheckBlockedFilters(element)) {
        //element.display = "background";
        this.filteredBlockedAppointments.push(element);
      }
    });
  }

  CheckBlockedFilters(app: block_appointment) {
    let flag: boolean = false;
    if (app.idPractitioner == this.idSelectedPractitioner) {
      flag = true;
    } else {
      return false;
    }
    return flag;
  }

  CheckFilters(app: Appointment) {
    let flag: boolean = false;
    /*if(!this.clinic.clientHasEDP) //ako je standalone, onda se prikazuju svi confirmed
    {
      if(app.idAppointmentStatus == 1)
      {
        flag = true;
      }
      else
      {
        return false;
      }
    }
    else //ako nije standalone
    {
      if(app.createdOnline == false) //prikazujemo sve appointmente iz edp-a
      {
        flag = true;
      }
      else
      {
        return false;
      }
    } 

    if(this.idSelectedPractitioner == -1) //ako je all da prikaze sve, treba jos vidjeti
    {
      return true;
    }
    else
    {
      if(app.idPractitioner == this.idSelectedPractitioner)
      {
        flag = true;
      }
      else
      {
        return false;
      }
    }*/
    if (app.idAppointmentStatus == 1) {
      flag = true;
    } else {
      return false;
    }

    if (app.idPractitioner == this.idSelectedPractitioner) {
      flag = true;
    } else {
      return false;
    }

    return flag;
  }

  SelectedDateChanged(date: DatesSetArg) {
    const calendarApi = this.calendarComponent.getApi();
    calendarApi.removeAllEventSources();
    this.endDate = new Date(date.end.setDate(date.end.getDate() + 1));
    this.startDate = new Date(date.start.setDate(date.start.getDate() - 1));
    this.LoadAppointments(this.startDate, this.endDate);
  }

  GetFirstDayOfCurrentMonth() {
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    firstDay.setDate(firstDay.getDate() - 1);
    return firstDay;
  }

  GetLastDayOfCurrentMonth() {
    let date = new Date();
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    lastDay.setDate(lastDay.getDate() + 1);
    return lastDay;
  }

  async LoadPractitioners() {
    this.practitioners = await this.webApiService.GetAllPractitioners(
      this.idClinic
    );
    if (this.practitioners.length > 0) {
      this.idSelectedPractitioner = this.practitioners[0].idPractitioner;
      this.Filter();
    }
  }

  FilterPractChanged(value: string) {
    this.filteredAppointments = [];
    this.filteredBlockedAppointments = [];
    this.idSelectedPractitioner = Number(value.split(':')[1]);
    this.Filter();
  }
  Filter() {
    this.appointments.forEach((element) => {
      if (this.CheckFilters(element)) {
        this.filteredAppointments.push(element);
      }
    });

    this.blockedAppointments.forEach((app) => {
      if (this.CheckBlockedFilters(app)) {
        this.filteredBlockedAppointments.push(app);
      }
    });
    this.SetAppointmentsInCalendar();
  }
  async GetAppointmentStatus() {
    this.statuses = await this.webApiService.GetAllAppStatuses();
  }
}
