import { Component, OnInit } from '@angular/core';
import { WebApiService } from '../services/web-api.service'
import { Appointment } from '../model/Appointment'
import * as moment from 'moment';
import { AppointmentStatus } from '../model/AppointmentStatus';
import { Router, ActivatedRoute } from '@angular/router';
import { Clinic } from '../model/Clinic';
import { AddAppointmentComponent } from '../add-appointment/add-appointment.component';
import { MatDialog } from '@angular/material/dialog';
import { QuestionModalComponent } from '../question-modal/question-modal.component';
import { ReasonModalComponent } from '../reason-modal/reason-modal.component';
import { practitioner } from '../model/Practitioner';
import { addDays } from '@fullcalendar/core';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss']
})
export class AppointmentsComponent implements OnInit {
  years: number[] = [2022, 2023, 2024, 2025, 2026, 2027]; //15 years shown
  months: string[] = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
  selectedYear: number;
  selectedMonth: string;
  isHidden = true;
  activeTab: number = 1;
  practitioners: practitioner[] = [];
  startDate: Date = new Date();
  monthStr: string = "";
  endDate: Date = new Date();
  AllAppointments: Appointment[] = [];
  FilteredAppointments: Appointment[] = [];
  onlyNew = false;// da li pokazumo samo pending appointmente ili sve
  onlyManual = false;
  showAll = false;
  selectedStatusId: number = 4; // ovo je all po difoltu
  selectedPractId: number = -1; // isto all po difoltu
  practNameFilterString: string = "";
  patientNameFilterString: string = "";
  selectedDateFrom: string = "";
  selectedDateTo: string = "";
  idClinic: number = Number(sessionStorage.getItem('idClinic'));
  Statuses: AppointmentStatus[] = [];
  lastAppointmentChecked: Appointment = new Appointment();
  clinic: Clinic = new Clinic();
  firstDayInMonth: Date;

  constructor(private matDialog: MatDialog, private webApiService: WebApiService, private router: Router, private route: ActivatedRoute) { }

  async ngOnInit() {
    await this.LoadPractitioners();
    await this.LoadClinic();
    await this.LoadRouterOptions();
    this.SetStartingDates();
    this.LoadAppointments(this.startDate, this.endDate);
    this.UncheckStyle();
    this.SetCurrentYearAndMonth();
  }

  async LoadPractitioners() {
    this.practitioners = await this.webApiService.GetAllPractitioners(this.idClinic);
  }
  async LoadClinic() {
    this.clinic = await this.webApiService.GetClinicById();
  }

  SetCurrentYearAndMonth() {
    let today = new Date();
    let year = today.getFullYear();
    this.selectedYear = this.years.filter(y => y == year)[0];
    let options = { month: 'long' } as Intl.DateTimeFormatOptions;
    let currentMonthName = today.toLocaleString('de-DE', options);
    this.selectedMonth = this.months.filter(m => m == currentMonthName)[0];
    this.CalculateFirstDayInMonth();
  }

  CalculateFirstDayInMonth()
  {
    this.firstDayInMonth = this.GetFirstDayOfMonthAndYear(this.selectedYear, this.months.indexOf(this.selectedMonth));
  }
  async LoadRouterOptions() {
    this.Statuses = await this.webApiService.GetAllAppStatuses();
    if (sessionStorage.getItem('onlyNew') == 'true') {
      this.onlyNew = true;
    }
    else {
      this.onlyNew = false;
    }
    if (sessionStorage.getItem('onlyManual') == 'true') {
      this.onlyManual = true;
    }
    else {
      this.onlyManual = false;
    }
    if (this.onlyNew) {
      this.selectedStatusId = -1; // SAMO NEW APPOINTMENTS
    }
    else if (this.onlyManual) {
      this.selectedStatusId = 2; // SAMO MANUAL APPOINTMENTS
    }
    else {
      this.showAll = true;
      this.selectedStatusId = 4; // ALL APPOINTMENTS
    }
  }

  ToggleDiv() {
    this.isHidden = !this.isHidden;
  }

 
  GoToToday() {
    this.UncheckStyle();
    let date = new Date();
    this.startDate = this.GetFirstDayOfMonth(date);
    this.endDate = this.GetLastDayOfMonth(date);
    this.LoadAppointments(this.startDate, this.endDate);
  }

  async LoadAppointments(startDate: Date, endDate: Date) {
    this.AllAppointments = [];
    this.FilteredAppointments = [];

    let apps;
    if (this.selectedStatusId == -1) {
      apps = await this.webApiService.GetPendingByClinic(this.idClinic);
    }
    else {
      apps = await this.webApiService.GetAllCreatedOnlineAppointments(this.idClinic, startDate.toISOString(), endDate.toISOString());
    }
    this.GenerateAgendaData(apps);
  }

  AppointmentSelectionChanged(app: Appointment) {
    if (app.isAppointmentSelected == true) {
      app.isAppointmentSelected = false;
      this.UncheckStyle();
    }
    else {
      this.CheckStyle()
      this.UncheckOther(app.idAppointment);
      app.isAppointmentSelected = true;
      this.lastAppointmentChecked = app;
    }
  }

  UncheckStyle() {
    document.documentElement.style.setProperty('--option-opacity', '0.3');
    document.documentElement.style.setProperty('--option-pointer-event', 'none');
    document.documentElement.style.setProperty('--option-cursor', 'none');
  }

  CheckStyle() {
    document.documentElement.style.setProperty('--option-opacity', '1.0');
    document.documentElement.style.setProperty('--option-pointer-event', 'all');
    document.documentElement.style.setProperty('--option-cursor', 'pointer');
  }

  UncheckOther(idAppointment: number) {
    this.FilteredAppointments.forEach(app => {
      if (app.isAppointmentSelected) {
        if (app.idAppointment != idAppointment) {
          app.isAppointmentSelected = false;
        }
      }
    });
  }

  SetStartingDates() {
    let date = new Date();
    //this.endDate = this.GetLastDayOfMonth(date);
    //this.startDate = this.GetFirstDayOfMonth(date);
    this.startDate = this.AddDays(date,  1 * 12 * (-30));
    this.endDate = this.AddDays(date, 4 * 12 * 30) //we are getting appointment for the last five and next five years;

    this.selectedDateFrom = moment(date).format("yyyy-MM-DD");
    this.selectedDateTo = moment(this.AddDays(date, 1 * 12 * 30)).format("yyyy-MM-DD");
  }

  GetFirstDayOfMonth(date: Date) {
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    this.monthStr = moment(firstDay).format("MMMM-YYYY");
    firstDay.setDate(firstDay.getDate() - 1);
    return firstDay;
  }

  GetLastDayOfMonth(date: Date) {
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    lastDay.setDate(lastDay.getDate() + 1);
    return lastDay;
  }

  AddDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  GenerateAgendaData(apps: any[]) {
    apps.forEach(element => {
      element.title = element.appointment_type;
      element.statusStr = this.Statuses.find(e => e.idStatus == element.idAppointmentStatus).statusNameEn;
      element.isAppointmentSelected = false;
      if (element.createdOnline) {
        this.AllAppointments.push(element);
        if (this.CheckFilters(element)) {
          this.FilteredAppointments.push(element);
        }
      }
    });

  }

 IsSameMonth(date1: Date, date2: Date): boolean {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth()
  );
}

 GetFirstDayOfMonthAndYear(year: number, month: number): Date{
  const date = new Date(year, month, 1);
  return date;
 }

  MonthChanged(value: string)
  {
    this.selectedMonth = value.split(":")[1].trim();
    this.CalculateFirstDayInMonth();
    this.FilterAppointments();
  }

  YearChanged(value: string)
  {
    this.selectedYear = Number(value.split(":")[1]);
    this.CalculateFirstDayInMonth();
    this.FilterAppointments();
  }


  FilterStatusChanged(value: string) {
    this.selectedStatusId = Number(value.split(":")[1]);
    this.FilterAppointments();
  }

  FilterPractitionerChanged(value: string){
    this.selectedPractId = Number(value.split(":")[1]);
    this.FilterAppointments();
  }



  FilterAppointments()
  {
    this.FilteredAppointments = [];
    this.AllAppointments.forEach(element => {
      if (this.CheckFilters(element)) {
        this.FilteredAppointments.push(element);
      }
    });
  }

  SwitchPeriod(value: number)
  {
    this.FilterAppointments();
  }

  CheckFilters(app: Appointment): boolean {
    let flag = true;

    if (this.selectedStatusId != 4) { //ako su svi selektovani preskacemo(4 je za all statuses)
      if (app.idAppointmentStatus == this.selectedStatusId) {
        flag = true;
      }
      else {
        return false;
      }
    }

    if (this.selectedPractId != -1){ //ako su svi selektovani preskacemo
      if (app.idPractitioner == this.selectedPractId){
        flag = true;
      }
      else{
        return false;
      }
    }

    if (this.activeTab == PeriodEnum.DayPeriod)
    {
      if (this.selectedDateFrom != "")
      {
        if (new Date(this.selectedDateFrom).setHours(0,0,0,0) <= new Date(app.date).setHours(0,0,0,0))
        {
          flag = true;
        }
        else{
          return false;
        }
      }
      if (this.selectedDateTo != "")
      {
        if (new Date(this.selectedDateTo).setHours(0,0,0,0) >= new Date(app.date).setHours(0,0,0,0))
        {
          flag = true;
        }
        else{
          return false;
        }
      }
    }
    else if (this.activeTab == PeriodEnum.MonthPeriod)
    {
      if (this.IsSameMonth(new Date(app.date),this.firstDayInMonth)){
        flag = true;
      }
      else{
        return false;
      }
    }


    return flag;
  }


  async onSelectedDateFromChanged(value) {
    this.selectedDateFrom = value;
    this.FilterAppointments();
  }

  async onSelectedDateToChanged(value) {
    this.selectedDateTo = value;
    this.FilterAppointments();
  }

  EditSelectedManualAppointment(appointment: Appointment) {
    this.OpenAddAppointmentModal(appointment);
  }

  AddNewManualAppointment() {
    this.lastAppointmentChecked = null;
    this.OpenAddAppointmentModal();
  }

  OpenAddAppointmentModal(appointment?: Appointment) {
    let dialogRef = this.matDialog.open(AddAppointmentComponent, {
      panelClass: 'dialog-class',
      data: {
        app: appointment
      },
      height: "auto",
      width: "600px"
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result == true) {
        window.location.reload();
      }
    })
  }

  async ConfirmSelectedPendingAppointment(appointment: Appointment) {
    appointment.idAppointmentStatus = 1;
    appointment.dateOfConfirmation = new Date();
    let ret = await this.webApiService.UpdateOnlineAppointment(appointment);
    window.location.reload();
  }

  async RejectSelectedPendingAppointment(appointment: Appointment) {
    appointment.idAppointmentStatus = 0;
    let ret = await this.webApiService.UpdateOnlineAppointment(appointment);
    window.location.reload();
  }

  async DeleteSelectedAppointment(appointment: Appointment) {

    let dialogRef = this.matDialog.open(ReasonModalComponent, {
      panelClass: 'dialog-class',
      height: "auto",
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result != false) {
        appointment.cancellingDate = new Date();
        appointment.remarks = result;
        appointment.idAppointmentStatus = 3;
        await this.webApiService.UpdateOnlineAppointment(appointment);
        //window.location.reload();
        await this.LoadAppointments(this.startDate, this.endDate);
      }
    })

  }

}

enum PeriodEnum {
  DayPeriod = 1,
  MonthPeriod = 2
}