import { Component,ViewChild,OnInit } from '@angular/core';
import { SharedService } from '../services/shared.service'
import { Router } from '@angular/router'
import { MatDialog } from "@angular/material/dialog";
import { AddAppointmentComponent } from '../add-appointment/add-appointment.component'
import { EditColoursComponent } from '../edit-colours/edit-colours.component';
import { Clinic } from '../model/Clinic';
import { WebApiService } from '../services/web-api.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  clinic: Clinic = new Clinic();
  clinicName: string = "demo";
  userRole: string = sessionStorage.getItem('userRole');
  idClinic: number = Number(sessionStorage.getItem('idClinic'));

  ngOnInit(): void {
    this.LoadClinic();
  }

  constructor( private sharedService : SharedService,
    private router: Router,
    private webApiService: WebApiService,
    private matDialog: MatDialog){
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
    }

ShowHideMenu()
{
  let sidebar = document.querySelector(".sidebar");
  sidebar.classList.toggle("close");

  let openButton = document.querySelector(".buttonOpen");
  openButton.classList.toggle("open");
  if(this.router.url == '/home/agenda')
  {
    this.sharedService.updateApprovalMessage(true);
  }
}
async LoadClinic()
{
  this.clinic = await this.webApiService.GetClinicById();
}

OpenSubmenu(event)
{
  let menu = document.getElementById(event.currentTarget.parentElement.id)
  menu.classList.toggle("showMenu")
}

OpenSubmenuLevel2(event)
{
  let menu = document.getElementById(event.currentTarget.parentElement.id)
  menu.classList.toggle("showMenu2")
}

SwitchToAgendaView()
{
  this.router.navigateByUrl('home/agenda');
}

SwitchToNewAppointmentsView()
{
  sessionStorage.setItem('onlyNew','true');
  sessionStorage.setItem('onlyManual','false');
  this.router.navigateByUrl('home/newAppointments')
}

SwitchToAllAppointmentsView()
{
  sessionStorage.setItem('onlyNew','false');
  sessionStorage.setItem('onlyManual','false');
  this.router.navigateByUrl('home/allAppointments');
}

SwitchToManualAppointmentsView()
{
  sessionStorage.setItem('onlyNew','false');
  sessionStorage.setItem('onlyManual','true');
  this.router.navigateByUrl('home/manualAppointments');
}

SwitchToHolidaysView()
{
  this.router.navigateByUrl('home/clinic-holidays');
}


SwitchToClinicInfoView()
{
  this.router.navigateByUrl('home/clinic-info');
}

LogoutUser()
{
  sessionStorage.clear();
  this.router.navigateByUrl('');
}

SwitchToClinicUsersView()
{
  this.router.navigateByUrl('home/clinic-users');
}

SwitchToPractitionersView()
{
  this.router.navigateByUrl('home/practitioners');
}
SwitchToAppointmentTypesView()
{
  this.router.navigateByUrl('home/appointmentTypes');
}

SwitchToPatientsView()
{
this.router.navigateByUrl('home/patients');
}

SwitchToBlockAppointmentsView()
{
  this.router.navigateByUrl('home/blocked');
}

SwitchToSmsLogView()
{
  this.router.navigateByUrl('home/logs');
}

OpenSmsConfiguration()
{
  this.router.navigateByUrl('home/sms');
}

OpenAddAppointmentModal()
{
  let dialogRef = this.matDialog.open(AddAppointmentComponent,{
    panelClass: 'dialog-class',
    data: {
      message: "message",
      flag: 'Notification',
      phoneNum: ''
    },
    height: "auto",
    width: "600px"
  });
  dialogRef.afterClosed().subscribe(async result => {
    if (result == true) 
    {
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.SwitchToManualAppointmentsView();
    });
    }
  })
}

OpenColorEditor()
{
  this.router.navigateByUrl('home/color');
}

SwitchToWorkingTimes()
{
  this.router.navigateByUrl('home/work-times');
}

}
