import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDatePipe'
})
export class CustomDatePipePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    let a : string[] = value.split(':');
    return a[0] + ":" + a[1];
  }

}
