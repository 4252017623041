import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialog } from "@angular/material/dialog";
import { clinic_user } from '../model/Clinic_User';
import { WebApiService } from '../services/web-api.service';
import { clinic_role } from '../model/ClinicRole'

@Component({
  selector: 'app-add-clinic-user',
  templateUrl: './add-clinic-user.component.html',
  styleUrls: ['./add-clinic-user.component.scss']
})
export class AddClinicUserComponent implements OnInit {
  errorMessage: string = "";
  isError: boolean = false;
  idClinic: number = Number(sessionStorage.getItem('idClinic'));
  confirmPassword: string = "";
  selectedDate: string = "";
  clinicRoles: clinic_role[] = []
  selectedRole: clinic_role = null;
  user: clinic_user = new clinic_user();
  isEditing: boolean = false;
  constructor(private webApiService: WebApiService, public matDialog:MatDialog, public dialogRef: MatDialogRef<AddClinicUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    {
      dialogRef.disableClose = true;
      if(data.userToEdit)
      {
        this.user = {...data.userToEdit};
        this.confirmPassword = data.userToEdit.password;
        this.isEditing = true;
      }
      else
      {

      }
    } 

  async ngOnInit()
  {
    this.clinicRoles = await this.webApiService.GetClinicRoles();
    if(this.isEditing)
    {
      this.selectedRole = this.clinicRoles.filter(r => r.idClinicRole == this.user.idClinicRole)[0];
    }
  }

  CloseModal()
  {
    this.dialogRef.close();
  }

  onSelectedDateChanged(value)
  {
    this.selectedDate = value;
  }

  async InsertClinicUser()
  {
    this.isError = false;
    if(this.Validate())
    {
      this.user.idClinicRole = this.selectedRole.idClinicRole;
      this.user.idClinic = this.idClinic;
      if(!this.isEditing)
      {
        this.user.firstLogin = new Date();
        this.user.lastLogin = new Date();
        let ret = await this.webApiService.InsertClinicUser(this.user);
      }
      else
      {
        let ret = await this.webApiService.UpdateClinicUser(this.user);
      }
      this.dialogRef.close(true);
    }
  }

  Validate()
  {
    if(this.user.password != this.confirmPassword)
    {
      this.errorMessage = "Password and confirm password must match!";
      this.isError = true;
      return false;
    }
    if(this.user.username && this.user.password && this.confirmPassword && this.user.email && this.selectedRole)
    {
      return true;
    }
    else
    {
      this.errorMessage = "Enter all fields with *";
      this.isError = true;
      return false;
    }
  }


}
