
<div class="header">
	Arbeitsintervalle
</div>
<div class="container">
	<div class="options">
		<button (click) = "DownloadPdf()" style="width: auto !important;height: 32px; "class="btn_confirm">
			<i style ="font-size: 16px;" class='bx bxs-download'></i>
			PDF
		</button>
		<div class="sub-option">
			<select style="margin-right: 10px; margin-top: 10px;" (input)="SelectedPractChanged($event.target.value)"  [(ngModel)]="idSelectedPractitioner" class="select_control">
				<option [ngValue] = "0" value="" disabled selected>Behandler auswählen</option>
				<option [ngValue] = "pract.idPractitioner" *ngFor = "let pract of practitioners">{{pract.name}}</option>
			</select>
			<select style="margin-top: 10px;" (input)="SelectedAppTypeChanged($event.target.value)"  [(ngModel)]="idSelectedAppType" class="select_control">
				<option [ngValue] = "0" value="" disabled selected>Terminart auswählen</option>
				<option [ngValue] = "appType.id" *ngFor = "let appType of appTypes">{{appType.descriptionEn}}</option>
			</select>
		</div>
	</div>
	<table id="pdfTable" *ngIf="idSelectedAppType > 0">
		<thead>
		  <tr>
			<th>Wochentag</th>
			<th>Wochentagstermine</th>
		  </tr>
		</thead>
		<tbody>
			<tr>
				<td day data-column="Weekday">Montag</td>
				<td class="flex-wrapper no-border" data-column="Termins">
					<div *ngFor = "let t of GetTerminsForDay(1)" class="termin">
						{{t.from | customDatePipe}} - {{t.to | customDatePipe}}
						<div (click) = "RemoveTermin(GetWeekDayForDay(1),t)" class="unselect">
							X
						</div>
					</div>
					<button (click) = "AddTerminDialog(GetWeekDayForDay(1),1)" class="btn_edit">
						<i style ="font-size: 20px;"  class='bx bx-plus-medical'></i>
					</button>
				</td>
			</tr>
			<tr>
				<td day data-column="Weekday">Dienstag</td>
				<td class="flex-wrapper no-border" data-column="Termins">
					<div *ngFor = "let t of GetTerminsForDay(2)" class="termin">
						{{t.from | customDatePipe}} - {{t.to | customDatePipe}}
						<div (click) = "RemoveTermin(GetWeekDayForDay(2),t)" class="unselect">
							X
						</div>
					</div>
					<button (click) = "AddTerminDialog(GetWeekDayForDay(2),2)" class="btn_edit">
						<i style ="font-size: 20px;"  class='bx bx-plus-medical'></i>
					</button>
				</td>
			</tr>
			<tr>
				<td day data-column="Weekday">Mittwoch</td>
				<td class="flex-wrapper no-border" data-column="Termins">
					<div *ngFor = "let t of GetTerminsForDay(3)" class="termin">
						{{t.from | customDatePipe}} - {{t.to | customDatePipe}}
						<div (click) = "RemoveTermin(GetWeekDayForDay(3),t)" class="unselect">
							X
						</div>
					</div>
					<button (click) = "AddTerminDialog(GetWeekDayForDay(3),3)" class="btn_edit">
						<i style ="font-size: 20px;"  class='bx bx-plus-medical'></i>
					</button>
				</td>
			</tr>
			<tr>
				<td day data-column="Weekday">Donnerstag</td>
				<td class="flex-wrapper no-border" data-column="Termins">
					<div *ngFor = "let t of GetTerminsForDay(4)" class="termin">
						{{t.from | customDatePipe}} - {{t.to | customDatePipe}}
						<div (click) = "RemoveTermin(GetWeekDayForDay(4),t)" class="unselect">
							X
						</div>
					</div>
					<button (click) = "AddTerminDialog(GetWeekDayForDay(4),4)" class="btn_edit">
						<i style ="font-size: 20px;"  class='bx bx-plus-medical'></i>
					</button>
				</td>
			</tr>
			<tr>
				<td day data-column="Weekday">Freitag</td>
				<td class="flex-wrapper no-border" data-column="Termins">
					<div *ngFor = "let t of GetTerminsForDay(5)" class="termin">
						{{t.from | customDatePipe}} - {{t.to | customDatePipe}}
						<div (click) = "RemoveTermin(GetWeekDayForDay(5),t)" class="unselect">
							X
						</div>
					</div>
					<button (click) = "AddTerminDialog(GetWeekDayForDay(5),5)" class="btn_edit">
						<i style ="font-size: 20px;"  class='bx bx-plus-medical'></i>
					</button>
				</td>
			</tr>
			<tr>
				<td day data-column="Weekday">Samstag</td>
				<td class="flex-wrapper no-border" data-column="Termins">
					<div *ngFor = "let t of GetTerminsForDay(6)" class="termin">
						{{t.from | customDatePipe}} - {{t.to | customDatePipe}}
						<div (click) = "RemoveTermin(GetWeekDayForDay(6),t)" class="unselect">
							X
						</div>
					</div>
					<button (click) = "AddTerminDialog(GetWeekDayForDay(6),6)" class="btn_edit">
						<i style ="font-size: 20px;"  class='bx bx-plus-medical'></i>
					</button>
				</td>
			</tr>
			<tr>
				<td day data-column="Weekday">Sonntag</td>
				<td class="flex-wrapper no-border" data-column="Termins">
					<div *ngFor = "let t of GetTerminsForDay(7)" class="termin">
						{{t.from | customDatePipe}} - {{t.to | customDatePipe}}
						<div (click) = "RemoveTermin(GetWeekDayForDay(7),t)" class="unselect">
							X
						</div>
					</div>
					<button (click) = "AddTerminDialog(GetWeekDayForDay(7),7)" class="btn_edit">
						<i style ="font-size: 20px;"  class='bx bx-plus-medical'></i>
					</button>
				</td>
			</tr>
		</tbody>
	  </table>
	<div *ngIf = "weekdayTermins.length < 1" class="no_termins_note">Behandler und Termintyp auswählen.</div>
</div>
