import { Clinic } from '../model/Clinic'
export class Company
{
    public clinics: Clinic[];
    public idCompany: number;
    public imageName: string
    public name: string
    public text: string
    public urlName: string;
    public descriptionDe: string;
    public descriptionEn: string;
    public email: string;
    public phone: string;
}