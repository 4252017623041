<div class="content">
    <div *ngIf="!isEditing" class="header">
        Neuen Behandler hinzufügen
    </div>
    <div *ngIf="isEditing" class="header">
        Behandler bearbeiten
    </div>
    <div class="form">

        <div class="field-label">
            Name*
        </div>
        <input type="text" [(ngModel)] = "pract.name" class="input_field"/>

        <div class="field-label">
            Funktion*
        </div>
        <input type="text" [(ngModel)] = "pract.function" class="input_field"/>

        <div class="field-label">
            Beschreibung
        </div>
        <input type="text" [(ngModel)] = "pract.description" class="input_field"/>

        <div class="field-label">
            Datum von
        </div>
        <app-date-picker [pickerWidth] = "'none'" (dateChange)="DateFromChanged($event)" [selectedDate]="pract.dateFrom"></app-date-picker>

        <div class="field-label">
            Datum bis
        </div>
        <app-date-picker [pickerWidth] = "'none'" (dateChange)="DateToChanged($event)" [selectedDate]="pract.dateTo"></app-date-picker>

        <div class="field-label">
            Aktiv?
        </div>
        <input style="width:20px;height:20px" type="checkbox" [(ngModel)] = "pract.isActive"/>

        <!--<div class="field-label">
            Appointment types
        </div>
        <div class="add_select_wrapper">
            <ng-select class="custom_ngselect"  appendTo="body"  [items]="appTypes"
                [virtualScroll]="true"
                bindLabel="descriptionEn"
                
                [multiple]="true"
                [(ngModel)] = "selectedAppTypes"
                #select 
                (change)="select.blur()"
                (focus)="select.blur()"
                (clear)="select.blur()">
            </ng-select>
        </div>

        <div style="margin-top: 10px;" class="field-label">
            Color
        </div>
        <div class="outter-color-picker">
            <div class="picker">
                <div class="color-picker-inner">
                    <input type="button" #colorPicker [cpCancelButton]="true" [cpCloseClickOutside]="false" [cpOKButton]="true" class="form-control" [(colorPicker)]="pract.colorBackground" cpWidth='230px' cpHeight="300px"
                    [style.background]="pract.colorBackground"  style="padding-left: 30px; width: 100px;" class="color-input"/>
    
                    <div class="color-arrow-div" (click) = "colorPicker.click()">
                        <i style="margin-left: 2px; position: relative; font-size: 15px;padding-top: 5px;" class='bx bxs-down-arrow'></i>
                    </div>
                </div>
            </div>
            <div style="display: flex;" class="color-text-value">
                <p class="hexcolor-p">
                    {{pract.colorBackground}}
                </p>
            </div>
        </div>-->

        <div style="margin-top: 10px;" class="field-label">
            Profilbild*
        </div>
        <input  (change)="handleFileInput($event.target.files)" style="margin-left: 10px; margin-top: 5px;" [(ngModel)] = "imageUrl" type="file" accept="image/*"/>
        <div style="height: 35px;">
            <div *ngIf = "isError" class="error">
                {{errorMessage}}
            </div>
        </div>
    </div>
    <div class="close_modal">
        <button (click) = "CloseModal()" class="btn_close">
            Abbruch
        </button>
        <button (click) = "InsertPractitioner()" class="btn_confirm">
            Speichern
        </button>
    </div>
</div>
