import { Component, OnInit } from '@angular/core';
import { WebApiService } from '../services/web-api.service';
import { smsLog } from '../model/SmsLog'

@Component({
  selector: 'app-sms-log',
  templateUrl: './sms-log.component.html',
  styleUrls: ['./sms-log.component.scss']
})
export class SmsLogComponent implements OnInit {
  idClinic: number = Number(sessionStorage.getItem('idClinic'));
  smsLogs: smsLog[] = [];
  constructor(private webApiService: WebApiService) { }

  ngOnInit() 
  {
    this.LoadSmsLogs();
  }

  async LoadSmsLogs()
  {
    this.smsLogs = await this.webApiService.GetSMSLogByClinic(); 
  }

}
