import { Component, OnInit } from '@angular/core';
import { practitioner } from '../model/Practitioner';
import { app_type_for_practitioner } from '../model/Appointment_type_practitioner';
import { app_type_for_clinic } from '../model/Appointment_type_clinic';
import { WebApiService } from '../services/web-api.service';
import { WeekdayTermin } from '../model/WeekdayTermin'
import { MatDialog } from '@angular/material/dialog';
import { AddWorkingTimeComponent } from '../add-working-time/add-working-time.component';
import { QuestionModalComponent } from '../question-modal/question-modal.component';
import { DayTermin } from '../model/DayTermin'
import { Router,ActivatedRoute } from '@angular/router';

import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-working-times',
  templateUrl: './working-times.component.html',
  styleUrls: ['./working-times.component.scss']
})
export class WorkingTimesComponent implements OnInit {
  idClinic: number = Number(sessionStorage.getItem('idClinic'))
  idSelectedPractitioner: number = 0;
  idSelectedAppType: number = 0;
  idSelectedPractAppType: number = 0;
  selectedPractitioner: practitioner = null;
  practitioners: practitioner[] = [];
  practAppTypes: app_type_for_practitioner[] = [];
  appTypes: app_type_for_clinic[] = [];
  weekdayTermins: WeekdayTermin[] = [];
  weekdayTerminToEdit: WeekdayTermin = null;
  idWeekDay: number = 0;
  isAppTypeSelected: boolean = false;
  isPractSelected: boolean = false;
  occupiedDayIndexes: number[] = [];
  constructor(private webApiService: WebApiService, private matDialog: MatDialog, private router: Router) { }

  async ngOnInit()
  {
    await this.LoadPractitioners();
    await this.LoadAppointmentTypes();
    await this.SetDefaultSelection();
  }

  async LoadPractitioners()
  {
    this.practitioners = await this.webApiService.GetAllPractitioners(this.idClinic);
  }

  async LoadAppointmentTypes()
  {
    this.appTypes = await this.webApiService.GetAllAppointmentTypesByClinic();
  }

  async SetDefaultSelection()
  {
    this.idSelectedPractitioner = this.practitioners[0].idPractitioner;
    this.selectedPractitioner = this.practitioners[0];
    this.idSelectedAppType = this.appTypes[0].id;
    this.isAppTypeSelected = true;
    this.isPractSelected = true;
    this.FindIdPractAppType();
    await this.LoadWeekdayTermins();
  }

  async SelectedPractChanged(value: string)
  {
    this.idSelectedAppType = 0;
    this.weekdayTermins = [];
    this.idSelectedPractitioner = Number(value.split(":")[1]);
    this.selectedPractitioner = this.practitioners.filter(p => p.idPractitioner == this.idSelectedPractitioner)[0];
    this.isPractSelected = true;
    if(this.idSelectedAppType != 0)
    {
      await this.LoadWeekdayTermins();
    }
  }

  async SelectedAppTypeChanged(value: string)
  {
    this.idSelectedAppType = Number(value.split(":")[1]);
    this.idSelectedPractAppType = 0;
    this.FindIdPractAppType();
    this.isAppTypeSelected = true;
    await this.LoadWeekdayTermins();
  }

  FindIdPractAppType()
  {
    this.selectedPractitioner.listAppTypes.forEach(element => {
      if(element.idAppTypeClinic == this.idSelectedAppType)
      {
        this.idSelectedPractAppType = element.id;
      }
    });
  }
  async LoadWeekdayTermins()
  {
    this.weekdayTermins = await this.webApiService.GetAppTypeTerminsByPract(this.idSelectedPractAppType); /////////////////////bobo
  }

  OpenAddWorkingTimeModal()
  {
    let dialogRef = this.matDialog.open(AddWorkingTimeComponent,{
      panelClass: 'dialog-class',
      data: {
        occupiedDayIndexes: this.occupiedDayIndexes,
        idSelectedAppType: this.idSelectedAppType,
        idSelectedPractitioner: this.idSelectedPractitioner,
        idWeekDay: this.idWeekDay,
        weekdayTerminToEdit: this.weekdayTerminToEdit
      },
      height: "auto",
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) 
      {
        this.idSelectedPractAppType = result;
        this.weekdayTermins = [];
        await this.LoadPractitioners();
        this.selectedPractitioner = this.practitioners.filter(p => p.idPractitioner == this.idSelectedPractitioner)[0];
        await this.LoadWeekdayTermins();
      }
    })
  }

  AddNewWorkingTime()
  {
    this.CheckAvailableDays();
    this.weekdayTerminToEdit = null;
    this.OpenAddWorkingTimeModal();
  }

  EditWorkingTime(termin: WeekdayTermin)
  {
    this.weekdayTerminToEdit = termin;
    this.OpenAddWorkingTimeModal();
  }

  async DeleteWorkingTime(termin: WeekdayTermin)
  {
    let dialogRef = this.matDialog.open(QuestionModalComponent,{
      panelClass: 'dialog-class',
      data: {
        question:"Are you sure that you want to delete selected working day?"
      },
      height: "auto",
    });    
    dialogRef.afterClosed().subscribe(async result => {
      if (result == true) 
      {
        await this.webApiService.DeleteAppTypeTerminsByPract(termin);
        this.LoadWeekdayTermins();
      }
    })
  }

  CheckAvailableDays()
  {
    this.occupiedDayIndexes = [];
    this.weekdayTermins.forEach(element => {
      this.occupiedDayIndexes.push(element.idWeekDay);
    });
  }

  async RemoveTermin(weekDayTermin: WeekdayTermin,termin: DayTermin)
  {
    let dialogRef = this.matDialog.open(QuestionModalComponent,{
      panelClass: 'dialog-class',
      data: {
        question:"Are you sure that you want to delete selected termin?"
      },
      height: "auto",
    });    
    dialogRef.afterClosed().subscribe(async result => {
      if (result == true) 
      {
        weekDayTermin.termins.splice(weekDayTermin.termins.indexOf(termin),1);
        let ret = await this.webApiService.UpdateAppTypeTerminsByPract(weekDayTermin);
        await this.LoadWeekdayTermins();
      }
    })
  }

  AddTerminDialog(weekDayTermin: WeekdayTermin, idWeekDay: number)
  {
    this.idWeekDay = idWeekDay;
    if(weekDayTermin)//ako vec postoji taj weekday
    {
      this.weekdayTerminToEdit = weekDayTermin;
    }
    else // ako ne postoji
    {
      this.weekdayTerminToEdit = null;
    }
    this.OpenAddWorkingTimeModal();
  }

  GetTerminsForDay(dayId: number)
  {
    return this.weekdayTermins.filter(wt => wt.idWeekDay == dayId)[0]?.termins;
  }

  GetWeekDayForDay(dayId: number)
  {
    return this.weekdayTermins.filter(wt => wt.idWeekDay == dayId)[0];
  }
  
  async DownloadPdf()
  {
    await this.PopulateData();
    this.router.navigate(['home/work-times-pdf',{pract: JSON.stringify(this.selectedPractitioner)}],{skipLocationChange:true})
  }
  async PopulateData()
  {
    for(let i = 0;i < this.selectedPractitioner.listAppTypes.length; i++)
    {
      this.selectedPractitioner.listAppTypes[i].weekdayTermins =  await this.webApiService.GetAppTypeTerminsByPract(this.selectedPractitioner.listAppTypes[i].id); 
    }
  }
}
