<div class="header">
    KLINIK-INFO
</div>
<div class="outter-container">
    <div class="section">
        <div class="section-title">
            Systembasisdaten
            <button (click) = "OpenFile()" style="width: auto !important; margin-top: 0px; margin-bottom: 0px;" class="btn_confirm">
                <input id="patientInput" type="file" style="display:none" accept=".xlsx" (change)="HandleInput($event.target.files)" multiple = "false">
                <i style ="font-size: 16px;" class='bx bx-import'></i>
                Patienten importieren
            </button>
        </div>
        <div class="row">
            <div class="name">              
                URL-Unternehmen
            </div>
            <div  class="value ">
                <textarea disabled = "true" spellcheck="false" [(ngModel)] = "company.urlName" cdkTextareaAutosize class="input_fields "></textarea>
            </div>
        </div>
        <div class="row">
            <div class="name">
                Id-Unternehmen
            </div>
            <div  class="value ">
                <textarea disabled = "true" spellcheck="false" [(ngModel)] = "company.idCompany" cdkTextareaAutosize class="input_fields "></textarea>
            </div>
        </div>
        <div class="row">
            <div class="name">
                URL-Praxis
            </div>
            <div  class="value ">
                <textarea disabled = "true" spellcheck="false" [(ngModel)] = "clinic.urlName" cdkTextareaAutosize class="input_fields "></textarea>
            </div>
        </div>
        <div class="row">
            <div class="name">
                Standalone-Version
            </div>
            <div class="value ">
                <div *ngIf = "!clinic.clientHasEDP" class="input_fields" >
                    Ja
                </div>
                <div *ngIf = "clinic.clientHasEDP" class="input_fields" >
                    Nein
                </div>      
            </div>
        </div>
    </div>

    <div class="section">
        <div style="display: flex;" class="section-header">
            <div class="section-title">
                Definition Firmenseite
            </div>
            <div class="section-actions">
                <button *ngIf = "!IsEditingCompany" (click) = "ToggleCompanyEditingData()" class="btn btn_edit">
                    Bearbeiten
                </button>
                <button *ngIf = "IsEditingCompany" (click) = "SaveChangesCompany()" class="btn btn_confirm">
                    Speichern
                </button>
                <button *ngIf = "IsEditingCompany" (click) = "DiscardChangesCompany()" class="btn btn_close">
                    Abbruch
                </button>
            </div>
        </div>
        
        <div class="row">
            <div class="name">
                Logo
            </div>
            <div  class="value">
                <div class="logo-container">
                    <img class="logo_img" src='{{GetPath()}}/assets/images/{{company.imageName}}' />
                    <button *ngIf = "IsEditingCompany" class="btn_upload" (click) = "OpenFileUploaderCompany()">
                        <input id="input_company" (change)="handleFileInputCompany($event.target.files)" [(ngModel)] = "imageUrl" accept="image/*" type="file" style="display:none">
                        <i class='bx bx-upload'></i>
                        Hochladen
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="name">
                Firmenname
            </div>
            <div class="value ">
                <textarea [disabled] = "!IsEditingCompany" spellcheck="false" [(ngModel)] = "company.name" cdkTextareaAutosize class="input_fields "></textarea>
            </div>
        </div>
        <div class="row">
            <div class="name">
                Begrüssungstext DE
            </div>
            <div class="value">
                <ejs-richtexteditor [disabled] = "!IsEditingCompany" spellcheck="false" [(ngModel)] = "company.descriptionDe" #RTE id='defaultRTE' (created)='onCreate()'>
                    <ng-template  #valueTemplate>
                        <p style="padding: 10px !important;">
                        </p>
                    </ng-template>
                </ejs-richtexteditor>
            </div>
        </div>


        <div class="row">
            <div class="name">
                Begrüssungstext EN
            </div>
            <div class="value">
                <ejs-richtexteditor [disabled] = "!IsEditingCompany" spellcheck="false" [(ngModel)] = "company.descriptionEn" #RTE id='defaultRTE' (created)='onCreate()'>
                    <ng-template   #valueTemplate>
                        <p style="padding: 10px !important;">
                        </p>
                    </ng-template>
                </ejs-richtexteditor>
            </div>
        </div>
        <div class="row">
            <div class="name">
                Firmentelefon
            </div>
            <div  class="value ">
                <textarea [disabled] = "!IsEditingCompany" spellcheck="false" [(ngModel)] = "company.phone" cdkTextareaAutosize class="input_fields  "></textarea>
            </div>
        </div>
        <div class="row" style="margin-bottom: 30px;">
            <div class="name">
                E-Mail
            </div>
            <div  class="value ">
                <textarea [disabled] = "!IsEditingCompany" spellcheck="false" [(ngModel)] = "company.email" cdkTextareaAutosize class="input_fields "></textarea>
            </div>
        </div>
    </div>

    <div class="section">
        <div style="display: flex;" class="section-header">
            <div class="section-title">
                Definition Praxisseite
            </div>
            <div class="section-actions">
                <button *ngIf = "!IsEditingHomeDef" (click) = "ToggleHomepageEditingData()" class="btn btn_edit">
                    Bearbeiten
                </button>
                <button *ngIf = "IsEditingHomeDef" (click) = "SaveChanges()" class="btn btn_confirm">
                    Speichern
                </button>
                <button *ngIf = "IsEditingHomeDef" (click) = "DiscardChangesHomepageDef()" class="btn btn_close">
                    Abbruch
                </button>
            </div>
        </div>
        <div class="row">
            <div class="name">
                Logo
            </div>
            <div  class="value">
                <div class="logo-container">
                    <img class="logo_img" src='{{GetPath()}}/assets/images/{{clinic.imageName}}' />
                    <button *ngIf = "IsEditingHomeDef" class="btn_upload" (click) = "OpenFileUploader()">
                        <input id="input_clinic" (change)="handleFileInput($event.target.files)" [(ngModel)] = "imageUrl" accept="image/*" type="file" style="display:none">
                        <i class='bx bx-upload'></i>
                        Hochladen
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="name">
                Praxisname
            </div>
            <div  class="value ">
                <textarea [disabled] = "!IsEditingHomeDef"  spellcheck="false" [(ngModel)] = "clinic.name" cdkTextareaAutosize class="input_fields "></textarea>
            </div>
        </div>
        <div class="row">
            <div class="name">    
                Begrüssungstext DE
            </div>
            <div class="value">
                <ejs-richtexteditor [disabled] = "!IsEditingHomeDef" spellcheck="false" [(ngModel)] = "clinic.descriptionDe" #RTE id='defaultRTE' (created)='onCreate()'>
                    <ng-template  #valueTemplate>
                        <p style="padding: 10px !important;">
                        </p>
                    </ng-template>
                </ejs-richtexteditor>
            </div>
        </div>
        <div class="row">
            <div class="name">
                Begrüssungstext EN
            </div>
            <div class="value">
                <ejs-richtexteditor [disabled] = "!IsEditingHomeDef" spellcheck="false" [(ngModel)] = "clinic.descriptionEn" #RTE id='defaultRTE' (created)='onCreate()'>
                    <ng-template  #valueTemplate>
                        <p style="padding: 10px !important;">
                        </p>
                    </ng-template>
                </ejs-richtexteditor>
            </div>
        </div>
        <div class="row">
            <div class="name">
                Praxistelefon
            </div>
            <div  class="value ">
                <textarea [disabled] = "!IsEditingHomeDef" spellcheck="false" [(ngModel)] = "clinic.phone" cdkTextareaAutosize class="input_fields "></textarea>
            </div>
        </div>
        <div class="row" style="margin-bottom: 30px;">
            <div class="name">
                E-Mail
            </div>
            <div  class="value ">
                <textarea [disabled] = "!IsEditingHomeDef" spellcheck="false" [(ngModel)] = "clinic.email" cdkTextareaAutosize class="input_fields "></textarea>
            </div>
        </div>
    </div>

    <div class="section">
        <div style="display: flex;" class="section-header">
            <div class="section-title">
                Terminverwaltung
            </div>
            <div class="section-actions">
                <button *ngIf = "!IsEditingAppManagement" (click) = "ToggleAppManagementEditingData()" class="btn btn_edit">
                    Bearbeiten
                </button>
                <button *ngIf = "IsEditingAppManagement" (click) = "SaveChanges()" class="btn btn_confirm">
                    Speichern
                </button>
                <button *ngIf = "IsEditingAppManagement" (click) = "DiscardChangesAppManagement()" class="btn btn_close">
                    Abbruch
                </button>
            </div>
        </div>
        <div class="row">
            <div class="name">  
                Administratorname
            </div>
            <div  class="value ">
                <textarea [disabled] = "!IsEditingAppManagement" spellcheck="false" [(ngModel)] = "clinic.adminName" cdkTextareaAutosize class="input_fields "></textarea>
            </div>
        </div>
        <div class="row">
            <div class="name">
                Administrator E-Mail
            </div>
            <div  class="value ">
                <textarea [disabled] = "!IsEditingAppManagement" spellcheck="false" [(ngModel)] = "clinic.adminEmail" cdkTextareaAutosize class="input_fields "></textarea>
            </div>
        </div>
        <div class="row">
            <div class="name">
                Administrator Telefon
            </div>
            <div  class="value ">
                <textarea [disabled] = "!IsEditingAppManagement" spellcheck="false" [(ngModel)] = "clinic.adminPhone" cdkTextareaAutosize class="input_fields "></textarea>
            </div>
        </div>
    </div>
</div>
