
<div class="content">
    <div class="header">
        Neuen Termintyp hinzufügen
    </div>
    <div class="form">

        <div class="field-label">
            Beschreibung englisch*
        </div>
        <input type="text" [(ngModel)] = "appType.descriptionEn" class="input_field"/>

        <div class="field-label">
            Beschreibung deutsch*
        </div>
        <input type="text" [(ngModel)] = "appType.descriptionDe" class="input_field"/>

        <div class="field-label">
            Farbe*
        </div>
        <div class="outter-color-picker">
            <div class="picker">
                <div class="color-picker-inner">
                    <input type="button" #colorPicker [cpCancelButton]="true" [cpCloseClickOutside]="false" [cpOKButton]="true" class="form-control" [(colorPicker)]="appType.color" cpWidth='230px' cpHeight="300px"
                    [style.background]="appType.color"  style="padding-left: 30px; width: 100px;" class="color-input"/>
    
                    <div class="color-arrow-div" (click) = "colorPicker.click()">
                        <i style="margin-left: 2px; position: relative; font-size: 15px;padding-top: 5px;" class='bx bxs-down-arrow'></i>
                    </div>
                </div>
            </div>
            <div style="display: flex;" class="color-text-value">
                <p class="hexcolor-p">
                    {{appType.color}}
                </p>
            </div>
        </div>

        <div class="field-label">
            Dauer
        </div>
        <input type="number" [(ngModel)] = "appType.duration" class="input_field"/>

        <div class="field-label">
            Verzögerung
        </div>
        <input style="margin-bottom: 0px;" type="number" [(ngModel)] = "appType.delay" class="input_field"/>

        <div style="height: 35px;">
            <div *ngIf = "isError" class="error">
                {{errorMessage}}
            </div>
        </div>
    </div>
    <div class="close_modal">
        <button (click) = "CloseModal(false)" class="btn_close">
            Abbruch
        </button>
        <button (click) = "InsertAppType()" class="btn_confirm">
            Speichern
        </button>
    </div>
</div>
