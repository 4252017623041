export class clinic_user
{
    public idClinicUser: number
    public username: string;
    public password: string;
    public email: string;
    public firstLogin: Date;
    public idClinic: number;
    public idClinicRole: number;
    public lastLogin: Date
    public clinicUID: string;
    public roleName: string;
    constructor()
    {
        this.username = "";
        this.password = "";
        this.clinicUID = "";
    }
}