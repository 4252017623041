import { color } from './Color'
export class Clinic {
    constructor() { }
    public idClinic: number;
    public descriptionDe: string;
    public descriptionEn: string;
    public name: string;
    public urlName: string;
    public adminName: string;
    public adminPhone: string;
    public adminEmail: string;
    public phone: string;
    public email: string;
    public idColors: number;
    public isDeleted: number;
    public sender: string;
    public idImage: number;
    public smsConfirmEn: string;
    public smsRejectEn: string
    public smsInsertEn: string;
    public smsConfirmDe: string;
    public smsRejectDe: string;
    public smsInsertDe: string;
    public idCompany: number;
    public clientHasEDP: boolean
    public color: color;
    public imageName: string;

    public isClientBlocked: boolean;
    public idCanton?: number
    public creditMinLimit: number;

}