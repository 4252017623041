import { Component, OnInit } from '@angular/core';
import { practitioner } from '../model/Practitioner';
import { WebApiService } from '../services/web-api.service';
import { MatDialog } from "@angular/material/dialog";
import { AddPractitionerComponent } from '../add-practitioner/add-practitioner.component';
import { QuestionModalComponent } from '../question-modal/question-modal.component';
import { InfoModalComponent } from '../info-modal/info-modal.component';


@Component({
  selector: 'app-practitioners',
  templateUrl: './practitioners.component.html',
  styleUrls: ['./practitioners.component.scss']
})
export class PractitionersComponent implements OnInit {
  idClinic: number = Number(sessionStorage.getItem('idClinic'));
  practitioners: practitioner[] = [];
  practToEdit: practitioner = null;
  constructor(private webApiService: WebApiService, private matDialog: MatDialog) { }

  async ngOnInit()
  {
    await this.LoadPractitioners();
  }

  async LoadPractitioners()
  {
    this.practitioners = await this.webApiService.GetAllPractitioners(this.idClinic);
  }

  OpenAddNewPractitioner()
  {
    let dialogRef = this.matDialog.open(AddPractitionerComponent,{
      panelClass: 'dialog-class',
      data: {
        practToEdit: this.practToEdit
      },
      height: "auto",
      width: "600px"
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result == true) 
      {
        window.location.reload();
      }
    })
  }

  GetPath(){
    return this.webApiService.GetAgendaClientPath();
  }

  OpenEditPractitioner(pract: practitioner)
  {
    this.practToEdit = pract;
    this.OpenAddNewPractitioner();
  }

  async DeletePractitioner(pract: practitioner)
  {
    let dialogRef = this.matDialog.open(QuestionModalComponent,{
      panelClass: 'dialog-class',
      data: {
        question:"Sind Sie sicher, dass Sie den ausgewählten Behandler löschen möchten?"
      },
      height: "auto",
    });    
    dialogRef.afterClosed().subscribe(async result => {
      if (result == true) 
      {
        let ret = await this.webApiService.DeletePractitioner(pract);
        if(ret == true)
        {
          await this.UpdatePositions(pract.orderNo);
          window.location.reload();
        }
        else
        {
          this.ShowInfo("Behandler hat Termine und kann nicht gelöscht werden.");
        }
      }
    })
  }

  ShowInfo(message: string)
  {
    let dialogRef = this.matDialog.open(InfoModalComponent,{
      panelClass: 'dialog-class',
      data: {
        question: message
      },
      height: "auto",
    });    
  }

  async UpdatePositions(orderNo: number)
  {
    for(const pract of this.practitioners)
    {
      if(pract.orderNo > orderNo)
      {
        pract.orderNo--;
        await this.webApiService.UpdatePractitioner(pract);
      }
    }
  }

  AddNewPractitioner()
  {
    this.practToEdit = null;
    this.OpenAddNewPractitioner();
  }

  async MovePractLeft(practRight: practitioner)
  {
    let startIndex = practRight.orderNo;
    if (startIndex > 1)
    {
      let practLeft: practitioner = this.practitioners.filter(p => p.orderNo == startIndex - 1)[0];
      let practRight1 = {...practRight}
      let practLeft1 = {...practLeft}
      practLeft1.orderNo = startIndex;
      practRight1.orderNo = startIndex - 1;
      await this.webApiService.UpdatePractitioner(practRight1);
      await this.webApiService.UpdatePractitioner(practLeft1);
      window.location.reload();
    }
  }

  async MovePractRight(practLeft: practitioner)
  {
    let startIndex = practLeft.orderNo;
    if (startIndex < this.practitioners.length)
    {
      let practRight: practitioner = this.practitioners.filter(p => p.orderNo == startIndex + 1)[0];
      let practRight1 = {...practRight}
      let practLeft1 = {...practLeft}
      practLeft1.orderNo = startIndex + 1;
      practRight1.orderNo = startIndex;
      await this.webApiService.UpdatePractitioner(practRight1);
      await this.webApiService.UpdatePractitioner(practLeft1);
      window.location.reload();
    }
  }

}
