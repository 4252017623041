<div class="header">
	Terminarten
</div>
<div class="container">
	<div class="options">
		<button (click) = "AddNewAppType()"  style="width: auto !important; margin-bottom: 15px;" class="btn_confirm">
			<i style ="font-size: 16px;" class='bx bx-plus-medical'></i>
			Neuen Terminart hinzufügen
		</button>
	</div>
	<table>
		<thead>
		  <tr>
			<th>Beschreibung Englisch</th>
			<th>Beschreibung Deutsch</th>
			<th>Verzögerung</th>
			<th>Dauer</th>
			<th></th>
		  </tr>
		</thead>
		<tbody>
		  <tr *ngFor = "let appType of appTypes">
			<td data-column="Username">{{appType.descriptionEn}}</td>
			<td data-column="Email">{{appType.descriptionDe}}</td>
			<td data-column="Delay">{{appType.delay}}</td>
			<td data-column="Duration">{{appType.duration}}</td>
			<td class="actions">
			  <button (click) = "OpenEditAppType(appType)" class="btn_edit">
				  <i style ="font-size: 22px;"  class='bx bxs-edit'></i>
			  </button>
			  <button (click) = "Delete(appType)" class="btn_delete">
				  <i style ="font-size: 22px;" class='bx bxs-trash-alt'></i>
			  </button>
			</td>
		  </tr>
		</tbody>
	  </table>
</div>
