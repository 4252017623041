import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'dateToTime'
})
export class DateToTimePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return moment(value).format("HH:mm");
  }

}
