<div class="header">
    SMS-KONFIGURATION
</div>
<div class="outter-container">
    <!--<div class="section">
        <div class="section-title">
            Sms configuration ASPSMS
        </div>
        <div class="details">
            EasyDentWeb OnlineAgenda sends SMS via the provider ASPSMS.<br>
            Start <div style="display:inline-flex; color: rgb(61, 69, 182);">www.aspsms.ch</div> and register.<br>
            You can now buy the desired number of SMS under the heading "ASPSMS Credits".
        </div>-->

    <div class="section">
        <div style="display: flex;" class="section-header">
            <div class="section-title">
                Richten Sie den ASPSMS-Anbieter ein
            </div>
            <div class="section-actions">
                <button *ngIf="!IsEditingAspSms" (click)="ToggleAspSms()" class="btn btn_edit">
                    Bearbeiten
                </button>
                <button *ngIf="IsEditingAspSms" (click)="SaveChangesAspSmsConfig()" class="btn btn_confirm">
                    Speichern
                </button>
                <button *ngIf="IsEditingAspSms" (click)="DiscardChangesAspSmsConfig()" class="btn btn_close">
                    Abbruch
                </button>
            </div>
        </div>
        <div class="row">
            <div class="name">
                SMS Provider
            </div>
            <div class="value">
                <p class="static-text">Um mit der Online-Agenda arbeiten zu können, müssen Sie über ein Konto beim
                    Anbieter aspsms.ch verfügen. Registrieren Sie sich zuerst bei aspsms und kaufen Sie Credits. Danach
                    können Sie hier Ihr eigenes Konto einrichten.</p>
            </div>
        </div>
        <div class="row">
            <div class="name">
                Benutzerschlüssel
            </div>
            <div class="value">
                <textarea [disabled]="!IsEditingAspSms" spellcheck="false" [(ngModel)]="smsConfig.userKey"
                    cdkTextareaAutosize class="input_fields"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="name">
                Nutzername
            </div>
            <div class="value">
                <textarea [disabled]="!IsEditingAspSms" spellcheck="false" [(ngModel)]="smsConfig.username"
                    cdkTextareaAutosize class="input_fields"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="name">
                Passwort
            </div>
            <div class="value">
                <textarea [disabled]="!IsEditingAspSms" spellcheck="false" [(ngModel)]="smsConfig.password"
                    cdkTextareaAutosize class="input_fields"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="name">
                Absender <br>(maximal 12 Buchstaben)
            </div>
            <div class="value">
                <textarea maxlength="12" [disabled]="!IsEditingAspSms" spellcheck="false" [(ngModel)]="smsConfig.sender"
                    cdkTextareaAutosize class="input_fields"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="name">
                Restguthaben
            </div>
            <div class="value">
                <textarea disabled="true" spellcheck="false" [(ngModel)]="credit" cdkTextareaAutosize
                    class="input_fields"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="name">
                Warnstufe SMS
            </div>
            <div class="value">
                <textarea [disabled]="!IsEditingAspSms" spellcheck="false" [(ngModel)]="clinic.creditMinLimit"
                    cdkTextareaAutosize class="input_fields"></textarea>
            </div>
        </div>
        <!--<div class="row">
            <ejs-richtexteditor #RTE id='defaultRTE' (created)='onCreate()'>
                <ng-template #valueTemplate>
                    <p>Snack: </p>
                    <p>Drink: </p>
                    <p>Sweet: </p>
                </ng-template>
            </ejs-richtexteditor>
        </div>-->
    </div>
    <div class="section">
        <div style="display: flex;" class="section-header">
            <div class="section-title">
                SMS-Text für Patienten nach neuem Termin
            </div>
            <div class="section-actions">
                <button *ngIf="!IsEditingTextAfterApp" (click)="ToggleTextAfterApp()" class="btn btn_edit">
                    Bearbeiten
                </button>
                <button *ngIf="IsEditingTextAfterApp" (click)="SaveChangesClinic()" class="btn btn_confirm">
                    Speichern
                </button>
                <button *ngIf="IsEditingTextAfterApp" (click)="DiscardChangesClinicAfterAp()" class="btn btn_close">
                    Abbruch
                </button>
            </div>
        </div>
        <div class="row">
            <div class="name">
                Text Englisch
            </div>
            <div class="value">
                <textarea [disabled]="!IsEditingTextAfterApp" spellcheck="false" [(ngModel)]="clinic.smsInsertEn"
                    cdkTextareaAutosize class="input_fields"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="name">
                Texte Deutsch
            </div>
            <div class="value">
                <textarea [disabled]="!IsEditingTextAfterApp" spellcheck="false" [(ngModel)]="clinic.smsInsertDe"
                    cdkTextareaAutosize class="input_fields"></textarea>
            </div>
        </div>
    </div>
    <div class="section">
        <div style="display: flex;" class="section-header">
            <div class="section-title">
                SMS-Text zur Terminbestätigung
            </div>
            <div class="section-actions">
                <button *ngIf="!IsEditingConfirmApp" (click)="ToggleTextConfirmApp()" class="btn btn_edit">
                    Bearbeiten
                </button>
                <button *ngIf="IsEditingConfirmApp" (click)="SaveChangesClinic()" class="btn btn_confirm">
                    Speichern
                </button>
                <button *ngIf="IsEditingConfirmApp" (click)="DiscardChangesClinicConfirmText()" class="btn btn_close">
                    Abbruch
                </button>
            </div>
        </div>
        <div class="row">
            <div class="name">
                Text Englisch
            </div>
            <div class="value">
                <textarea [disabled]="!IsEditingConfirmApp" spellcheck="false" [(ngModel)]="clinic.smsConfirmEn"
                    cdkTextareaAutosize class="input_fields"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="name">
                Texte Deutsch
            </div>
            <div class="value">
                <textarea [disabled]="!IsEditingConfirmApp" spellcheck="false" [(ngModel)]="clinic.smsConfirmDe"
                    cdkTextareaAutosize class="input_fields"></textarea>
            </div>
        </div>
    </div>
    <div class="section">
        <div style="display: flex;" class="section-header">
            <div class="section-title">
                SMS-Text zur Terminabsage
            </div>
            <div class="section-actions">
                <button *ngIf="!IsEditingRejectApp" (click)="ToggleTextRejectApp()" class="btn btn_edit">
                    Bearbeiten
                </button>
                <button *ngIf="IsEditingRejectApp" (click)="SaveChangesClinic()" class="btn btn_confirm">
                    Änderungen speichern
                </button>
                <button *ngIf="IsEditingRejectApp" (click)="DiscardChangesClinicRejectText()" class="btn btn_close">
                    Änderungen verwerfen
                </button>
            </div>
        </div>
        <div class="row">
            <div class="name">
                Text Englisch
            </div>
            <div class="value">
                <textarea [disabled]="!IsEditingRejectApp" spellcheck="false" [(ngModel)]="clinic.smsRejectEn"
                    cdkTextareaAutosize class="input_fields"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="name">
                Texte Deutsch
            </div>
            <div class="value">
                <textarea [disabled]="!IsEditingRejectApp" spellcheck="false" [(ngModel)]="clinic.smsRejectDe"
                    cdkTextareaAutosize class="input_fields"></textarea>
            </div>
        </div>
    </div>
    <div class="section">
        <div class="section-title">
            Bedeutung der Platzhalter
        </div>
        <div class="details">
            {{ '{' }}pr{{'}'}} = Behandler<br>
            {{ '{' }}dt{{'}'}} = Termin<br>
            {{ '{' }}tf{{'}'}} = Termin ab<br>
            {{ '{' }}at{{'}'}} = Art der Behandlung<br>
        </div>
    </div>
</div>