<body>
    <div id="bg"></div>
    
    <form>
      <div class="form-field">
        <input [(ngModel)] = "user.clinicUID" name="key" type="text" placeholder="Eindeutige ID" required/>                         
      </div>
      <div class="form-field">
        <input [(ngModel)] = "user.username" name="un" type="email" placeholder="Benutzername" required/>
      </div>
      <div class="form-field">
        <input [(ngModel)] = "user.password" name="pw" type="password" placeholder="Passwort" required/>                         
      </div>
      <div *ngIf = "isError" class="error">
          {{errorMessage}}
      </div>
      <div (click) = "ForgotPasswordClicked()" class="form-field forgot_password">
        <div>Passwort vergessen?</div>
        <div>Klicken Sie hier, um ein neues Passwort per E-Mail zu erhalten.</div>
      </div>
      
      <div class="form-field">
        <button (click) = "LogIn()" class="btn" type="submit">Anmeldung</button>
      </div>
    </form>  
    </body>
