import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialog } from "@angular/material/dialog";
import { practitioner } from '../model/Practitioner';
import { WebApiService } from '../services/web-api.service';
import { Router } from '@angular/router'
import { app_type_for_clinic } from '../model/Appointment_type_clinic';
import { app_type_for_practitioner } from '../model/Appointment_type_practitioner';
import { Clinic } from '../model/Clinic';

@Component({
  selector: 'app-add-practitioner',
  templateUrl: './add-practitioner.component.html',
  styleUrls: ['./add-practitioner.component.scss']
})
export class AddPractitionerComponent implements OnInit {
  errorMessage: string = "";
  pract: practitioner = new practitioner();
  practitioners: practitioner[] = [];
  isError: boolean = false;
  imageUrl: string = "";
  fileToUpload: File | null = null;
  formData: FormData;
  idClinic: number = Number(sessionStorage.getItem('idClinic'));
  isEditing: boolean = false;
  appTypes: app_type_for_clinic[] = [];
  selectedAppTypeId: number = 0;
  selectedAppTypes: app_type_for_clinic[] = [];
  clinic: Clinic = new Clinic();
  constructor(private router: Router, public webApiService: WebApiService, public matDialog:MatDialog, public dialogRef: MatDialogRef<AddPractitionerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    {
      dialogRef.disableClose = true;
      if(data.practToEdit)
      {
        this.pract = {...data.practToEdit};
        this.isEditing = true;
        this.PopulateSelectedAppTypes();
      }
    }

  async ngOnInit()
  {
    await this.LoadAppointmentTypes();
    await this.LoadClinic();
    await this.LoadPractitioners();
  }

  CloseModal()
  {
    this.dialogRef.close();
  }

  async LoadPractitioners()
  {
    this.practitioners = await this.webApiService.GetAllPractitioners(this.idClinic);
  }

  InsertAppTypes()
  {
    this.selectedAppTypes.forEach(element => {
      let appType: app_type_for_practitioner = new app_type_for_practitioner();
      appType.idAppTypeClinic = element.id;
      appType.appTypeClinic = element;
      this.pract.listAppTypes.push(appType)
    });
  }

  PopulateSelectedAppTypes()
  {
    this.pract.listAppTypes.forEach(element => {
      this.selectedAppTypes.push(element.appTypeClinic);
    });
  }

  async InsertPractitioner()
  {
    this.isError = false;
    if(this.ValidateInput())
    {
      if(!this.isEditing)
      {
        this.pract.idClinic = this.idClinic.toString();
        this.pract.orderNo = this.practitioners.length + 1;
        this.pract.image = null;
        this.pract.listAppTypes = [];
        this.InsertAppTypes();
        let newPract = await this.webApiService.InsertNewPractitioner(this.pract);
        let ret = await this.webApiService.UploadPractImage(newPract['idPractitioner'],this.formData)
        if(String(ret).length > 0) // ako je snimio na putanju
        {
          this.dialogRef.close(true);
        }
      }
      else
      {
        this.pract.listAppTypes = [];
        this.InsertAppTypes();
        let editedPract = await this.webApiService.UpdatePractitioner(this.pract);
        if(this.formData)
        {
          let ret = await this.webApiService.UploadPractImage(this.pract['idPractitioner'],this.formData)
          if(String(ret).length > 0) // ako je snimio na putanju
          {
            this.dialogRef.close(true);
          }
        }
        else
        {
          this.dialogRef.close(true);
        }
      }
    }
  }

  async handleFileInput(files: FileList) 
  {
    this.fileToUpload = files.item(0);
    this.PrepareUploadImage();
  }

  async PrepareUploadImage()
  {
    this.formData = new FormData();
    this.formData.append('image', this.fileToUpload, this.pract.name + ".png");
  }

  async LoadAppointmentTypes()
  {
    this.appTypes = await this.webApiService.GetAllAppointmentTypesByClinic();
  }

  ValidateInput()
  {
    if(!this.isEditing)
    {
      if(this.pract.name && this.pract.function  && this.fileToUpload)
      {
        return true;
      }
      else 
      {
        this.errorMessage = "Enter all fields with *";
        this.isError = true;
        return false;
      }
    }
    else
    {
      if(this.pract.name && this.pract.function)
      {
        return true;
      }
      else 
      {
        this.errorMessage = "Enter all fields with *";
        this.isError = true;
        return false;
      }
    }

  }

  async LoadClinic()
  {
    this.clinic = await this.webApiService.GetClinicById();
    if(!this.isEditing)
    {
      this.pract.colorBackground = this.clinic.color.btnAppType;
    }
  }

  async DateFromChanged(value)
  {
    this.pract.dateFrom = value;
  }

  async DateToChanged(value)
  {
    this.pract.dateTo = value;
  }



}
