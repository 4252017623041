<div class="header">
    FARBVERWALTUNG DESIGN
</div>
<div class="outter-container">

    <div class="subheader">
        Designfarben
    </div>
    <div class="field-label">
        Kopfzeile des Kalenders
    </div>
    <div class="outter-color-picker">
        <div class="picker">
            <div class="color-picker-inner">
                <input type="button" #colorPicker1 [cpCancelButton]="true" [cpCloseClickOutside]="false" [cpOKButton]="true" class="form-control" [(colorPicker)]="color.calendarTop" cpWidth='230px' cpHeight="300px"
                [style.background]="color.calendarTop"  style="padding-left: 30px;" class="color-input"/>

                <div class="color-arrow-div" (click) = "colorPicker1.click()">
                    <i style="margin-left: 2px; position: relative; font-size: 15px;padding-top: 5px;" class='bx bxs-down-arrow'></i>
                </div>
            </div>
        </div>
        <div style="display: flex;" class="color-text-value">
            <p class="hexcolor-p">
                {{color.calendarTop}}
            </p>
            <img class="zoom" src='{{GetPath()}}/assets/images/preview/calendar-header.png' />
        </div>
    </div>
    <div class="field-label">
        Kalenderuntertitel
    </div>
    <div class="outter-color-picker">
        <div class="picker">
            <div class="color-picker-inner">
                <input type="button" #colorPicker3 [cpCancelButton]="true" [cpCloseClickOutside]="false" [cpOKButton]="true" class="form-control" [(colorPicker)]="color.menu" cpWidth='230px' cpHeight="300px"
                [style.background]="color.menu"  style="padding-left: 30px;" class="color-input"/>

                <div class="color-arrow-div" (click) = "colorPicker3.click()">
                    <i style="margin-left: 2px; position: relative; font-size: 15px;padding-top: 5px;" class='bx bxs-down-arrow'></i>
                </div>
            </div>
        </div>
        <div style="display: flex;" class="color-text-value">
            <p class="hexcolor-p">
                {{color.menu}}
            </p>
            <img class="zoom" src='{{GetPath()}}/assets/images/preview/calendar-subheader.png' />
        </div>
    </div>
    <div class="field-label">
        Kalendertage
    </div>
    <div class="outter-color-picker">
        <div class="picker">
            <div class="color-picker-inner">
                <input type="button" #colorPicker2 [cpCancelButton]="true" [cpCloseClickOutside]="false" [cpOKButton]="true" class="form-control" [(colorPicker)]="color.calendarDays" cpWidth='230px' cpHeight="300px"
                [style.background]="color.calendarDays"  style="padding-left: 30px;" class="color-input"/>

                <div class="color-arrow-div" (click) = "colorPicker2.click()">
                    <i style="margin-left: 2px; position: relative; font-size: 15px;padding-top: 5px;" class='bx bxs-down-arrow'></i>
                </div>
            </div>
        </div>
        <div style="display: flex;" class="color-text-value">
            <p class="hexcolor-p">
                {{color.calendarDays}}
            </p>
            <img class="zoom" src='{{GetPath()}}/assets/images/preview/calendar-days.png' />
        </div>
    </div>
    <div class="subheader">
        Terminfarben buchen
    </div>
    <div class="field-label">
        Termin wählen
    </div>
    <div class="outter-color-picker">
        <div class="picker">
            <div class="color-picker-inner">
                <input type="button" #colorPicker4 [cpCancelButton]="true" [cpCloseClickOutside]="false" [cpOKButton]="true" class="form-control" [(colorPicker)]="color.btnBookApp" cpWidth='230px' cpHeight="300px"
                [style.background]="color.btnBookApp"  style="padding-left: 30px;" class="color-input"/>

                <div class="color-arrow-div" (click) = "colorPicker4.click()">
                    <i style="margin-left: 2px; position: relative; font-size: 15px;padding-top: 5px;" class='bx bxs-down-arrow'></i>
                </div>
            </div>
        </div>
        <div style="display: flex;" class="color-text-value">
            <p class="hexcolor-p">
                {{color.btnBookApp}}
            </p>
            <img class="zoom" src='{{GetPath()}}/assets/images/preview/termin-arrow.png' />
        </div>
    </div>
    <div class="field-label">
        Terminart
    </div>
    <div class="outter-color-picker">
        <div class="picker">
            <div class="color-picker-inner">
                <input type="button" #colorPicker5 [cpCancelButton]="true" [cpCloseClickOutside]="false" [cpOKButton]="true" class="form-control" [(colorPicker)]="color.btnAppType" cpWidth='230px' cpHeight="300px"
                [style.background]="color.btnAppType"  style="padding-left: 30px;" class="color-input"/>

                <div class="color-arrow-div" (click) = "colorPicker5.click()">
                    <i style="margin-left: 2px; position: relative; font-size: 15px;padding-top: 5px;" class='bx bxs-down-arrow'></i>
                </div>
            </div>
        </div>
        <div style="display: flex;" class="color-text-value">
            <p class="hexcolor-p">
                {{color.btnAppType}}
            </p>
            <img class="zoom" src='{{GetPath()}}/assets/images/preview/AppType-arrow.png' />
        </div>
    </div>    
</div>
<div style="display: flex;" class="close_modal">
    <div class="save-div">
        <button (click) = "Save()" class="btn_confirm">
            Speichern
        </button>
    </div>
</div>
