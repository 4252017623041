import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select'
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { AgendaComponent } from './agenda/agenda.component';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppointmentsComponent } from './appointments/appointments.component';
import { AddAppointmentComponent } from './add-appointment/add-appointment.component';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatDialogModule } from '@angular/material/dialog';
import { ClinicInfoComponent } from './clinic-info/clinic-info.component';
import { ClinicUsersComponent } from './clinic-users/clinic-users.component';
import { AddClinicUserComponent } from './add-clinic-user/add-clinic-user.component';
import { PractitionersComponent } from './practitioners/practitioners.component';
import { EditColoursComponent } from './edit-colours/edit-colours.component'
import { ColorSketchModule } from 'ngx-color/sketch';
import { ColorPickerModule } from 'ngx-color-picker';
import { DateToTimePipe } from './pipes/date-to-time.pipe';
import { AppointmentTypesComponent } from './appointment-types/appointment-types.component';
import { PatientsComponent } from './patients/patients.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { BlockedAppointmentsComponent } from './blocked-appointments/blocked-appointments.component';
import { AddBlockedAppointmentComponent } from './add-blocked-appointment/add-blocked-appointment.component';
import { SmsConfigurationComponent } from './sms-configuration/sms-configuration.component';
import { DateFormatPipe } from './pipes/date-format.pipe';

import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { AddPractitionerComponent } from './add-practitioner/add-practitioner.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { JwtInterceptor } from './auth/interceptor';
import { IsLoggedGuard } from './auth/IsLoggedGuard'
import { AddAppointmentTypeComponent } from './add-appointment-type/add-appointment-type.component';
import { SmsLogComponent } from './sms-log/sms-log.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { WorkingTimesComponent } from './working-times/working-times.component';
import { AddWorkingTimeComponent } from './add-working-time/add-working-time.component';
import { CustomDatePipePipe } from './pipes/custom-date-pipe.pipe';
import { IndexToDayPipe } from './pipes/index-to-day.pipe';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { QuestionModalComponent } from './question-modal/question-modal.component';
import { WorkingTimePDFComponent } from './working-time-pdf/working-time-pdf.component';
import { ReasonModalComponent } from './reason-modal/reason-modal.component';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { ClinicHolidaysComponent } from './clinic-holidays/clinic-holidays.component';
import { ConfigService } from './services/config.service'



export function loadConfig(configService: ConfigService) {
  return () => configService.loadConfig();
}

const appRoutes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LoginComponent },
  { path: 'home', loadChildren: () => import(`./home/home.module`).then(m => m.HomeModule), canActivate: [IsLoggedGuard] }
];

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
])

@NgModule({
  declarations: [
    AppComponent,
    AgendaComponent,
    AppointmentsComponent,
    AddAppointmentComponent,
    ClinicInfoComponent,
    ClinicUsersComponent,
    AddClinicUserComponent,
    PractitionersComponent,
    EditColoursComponent,
    DateToTimePipe,
    AppointmentTypesComponent,
    PatientsComponent,
    LoginComponent,
    HomeComponent,
    BlockedAppointmentsComponent,
    AddBlockedAppointmentComponent,
    SmsConfigurationComponent,
    DateFormatPipe,
    AddPractitionerComponent,
    DatePickerComponent,
    AddAppointmentTypeComponent,
    SmsLogComponent,
    WorkingTimesComponent,
    AddWorkingTimeComponent,
    CustomDatePipePipe,
    IndexToDayPipe,
    QuestionModalComponent,
    WorkingTimePDFComponent,
    ReasonModalComponent,
    InfoModalComponent,
    ClinicHolidaysComponent,


  ],
  entryComponents: [AddAppointmentComponent, AddClinicUserComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    BrowserModule,
    RichTextEditorModule,
    RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled' }),
    FullCalendarModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatStepperModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    ColorSketchModule,
    ColorPickerModule,
    NgxMaterialTimepickerModule

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      deps: [ConfigService],
      multi: true,
    },
    IsLoggedGuard,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
